import React, { useState, useCallback, useEffect } from 'react';
import {
  TextField,
  Autocomplete,
  Box,
  Fab,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { debounce } from 'lodash';
import SaveIcon from '@mui/icons-material/Save';
import { jsPDF } from 'jspdf';
import { callAddFont } from '@/Roboto-Regular-normal.js';
import { callAddBoldFont } from '@/Roboto-Bold-bold.js';
import { callAddItalicFont } from '@/Roboto-Italic-italic.js';

import CustomMap from './AnotherNewMap2';
import WaypointMarker from './icons/WaypointMarkerIcon';
import SwapIcon from './icons/SwapIcon';
import HidePointsIcon from './icons/HidePointsIcon';
import StartLocationIcon from './icons/StartLocationIcon';
import EndLocationIcon from './icons/EndLocationIcon';
import ArrowAltRightIcon from './icons/ArrowAltRightIcon';
import ArrowAltLeftIcon from './icons/ArrowAltLeftIcon';
import {
  useCreateSavedRoute,
  useUpdateSavedRoute,
} from '@/hooks/admin/useSavedRoutes';
import { useConfigurations } from '@/hooks/admin/useConfigurations';
import { useVehicles } from '@/hooks/admin/useVehicles';
import { pageRoutes } from '@/constants';
import { useLocation } from 'react-router-dom';
import { useNZTA } from '@/hooks/admin/useNzta';
interface Waypoint {
  name?: string;
  coordinates: [number, number];
}
interface RouteData {
  _id?: string;
  start?: {
    name: string;
    coordinates: [number, number];
  } | null;
  end?: {
    name: string;
    coordinates: [number, number];
  } | null;
  waypoints?: Waypoint[];
  geojsonData: any[];
}
interface Suggestion {
  id: string;
  place_name: string;
  center: [number, number];
}

/**
 * The component can optionally receive a pre-existing RouteData object
 * to re-render a saved route (routeData?), plus other props controlling UI.
 *
 * If you only want to pass in geojson data and not a saved route,
 * you can omit routeData.
 */
interface MapWithAutocompleteProps {
  /**
   * If provided, your component can initialize from this saved route object.
   */
  routeData?: RouteData;

  company: string;
  configuration: string;

  /**
   * Whether or not the side bar is displayed and a function to toggle it.
   */
  displayLeftBar: boolean;
  switchLeftBar: () => void;
}

// todo: move this to a shared utils file
function matchRoute(pathname: string) {
  for (const route of pageRoutes) {
    const regexPath = route.path.replace(/:[^\s/]+/g, '([^/]+)');
    const regex = new RegExp(`^${regexPath}$`);

    if (regex.test(pathname)) {
      return route.name;
    }
  }
  return 'Not Found'; // Return a default value if no match is found
}

const MapWithAutocomplete: React.FC<MapWithAutocompleteProps> = ({
  routeData,
  configuration,
  company,
  displayLeftBar,
  switchLeftBar,
}) => {
  const { pathname } = useLocation();
  const routeName = matchRoute(pathname);
  const [startInput, setStartInput] = useState('');
  const [endInput, setEndInput] = useState('');
  const [startSuggestions, setStartSuggestions] = useState<Suggestion[]>([]);
  const [endSuggestions, setEndSuggestions] = useState<Suggestion[]>([]);
  const [startPoint, setStartPoint] = useState<[number, number] | null>(null);
  const [endPoint, setEndPoint] = useState<[number, number] | null>(null);
  const [waypoints, setWaypoints] = useState<[number, number][]>([]);
  const [isWaypointMode, setIsWaypointMode] = useState(false);
  const [startValue, setStartValue] = useState<Suggestion | null>(null);
  const [endValue, setEndValue] = useState<Suggestion | null>(null);
  const [showHighRiskOnly, setShowHighRiskOnly] = useState(false);
  const [totalDistance, setTotalDistance] = useState(0);
  const [estimatedDuration, setEstimatedDuration] = useState(0);
  const [turnInstructions, setTurnInstructions] = useState<string[]>([]);
  const [tilesetIds, setTilesetIds] = useState<string[]>([]);
  const [rcaTilesetIds, setRcaTilesetIds] = useState<string[]>([]);

  const createSavedRoute = useCreateSavedRoute();
  const updateSavedRoute = useUpdateSavedRoute();
  const { data: configurations } = useConfigurations();
  const { data: vehicles } = useVehicles();
  const { data: nztaData, isLoading } = useNZTA();

  const [geojsonArray, setGeojsonArray] = useState<any[]>([]);

  useEffect(() => {
    if (routeData) {
      const { start, end, waypoints, geojsonData } = routeData;

      if (start) {
        setStartInput(start.name);
        setStartPoint(start.coordinates);
        setStartValue({
          id: 'start',
          place_name: start.name,
          center: start.coordinates,
        });
      }

      if (end) {
        setEndInput(end.name);
        setEndPoint(end.coordinates);
        setEndValue({
          id: 'end',
          place_name: end.name,
          center: end.coordinates,
        });
      }

      if (waypoints) {
        setWaypoints(waypoints.map((wp) => wp.coordinates));
      }

      if (geojsonData) {
        setGeojsonArray(geojsonData);
      }
    }
  }, [routeData]);

  useEffect(() => {
    if (!nztaData || !configuration) return;

    const currentConfiguration = configurations?.find(
      (c) => c._id === configuration,
    );

    if (
      !currentConfiguration?.permits ||
      !Array.isArray(currentConfiguration.permits)
    ) {
      return;
    }

    setTilesetIds(() => {
      const tilesetIds: string[] = [];

      currentConfiguration.permits.forEach((permit) => {
        if (permit.councils?.length) {
          const nztaItem = nztaData.find((ta) => permit.rca === ta._id);
          if (nztaItem?.councils) {
            nztaItem.councils
              .filter((council) =>
                permit.councils.find((c) => council._id === c._id),
              )
              .forEach((council) => {
                tilesetIds.push(council.tilesetId);
              });
          }
        }
      });

      const uniqueTilesetIds = [...new Set(tilesetIds)];
      return uniqueTilesetIds;
    });

    setRcaTilesetIds(() => {
      const rcaTilesetIds: string[] = [];

      currentConfiguration.permits.forEach((permit) => {
        if (permit.councils?.length) {
          return;
        }

        const nztaItem = nztaData.find((ta) => permit.rca === ta._id);
        if (nztaItem?.tilesetId) {
          rcaTilesetIds.push(nztaItem?.tilesetId);
        }
      });

      const uniqueRcaTilesetIds = [...new Set(rcaTilesetIds)];
      return uniqueRcaTilesetIds;
    });
  }, [nztaData, configuration, configurations]);

  const accessToken =
    'pk.eyJ1Ijoib3JjaGlkd2ViIiwiYSI6ImNtOG1jcWM5cjBiNzYycXBtbWhzMXI5YW0ifQ.tu_IuquB3UhBfXataJpe4w';

  const fetchSuggestions = async (
    input: string,
    setter: React.Dispatch<React.SetStateAction<Suggestion[]>>,
  ) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      input,
    )}.json?access_token=${accessToken}&autocomplete=true&limit=5&country=nz`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      const suggestions: Suggestion[] = data.features.map((feature: any) => ({
        id: feature.id,
        place_name: feature.place_name,
        center: feature.center,
      }));

      setter(suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const debouncedFetchSuggestionsStart = useCallback(
    debounce((value: string) => {
      fetchSuggestions(value, setStartSuggestions);
    }, 500),
    [],
  );

  const debouncedFetchSuggestionsEnd = useCallback(
    debounce((value: string) => {
      fetchSuggestions(value, setEndSuggestions);
    }, 500),
    [],
  );

  const toggleWaypointMode = () => {
    setIsWaypointMode(!isWaypointMode);
  };

  const handleSaveRoute = useCallback(async () => {
    const routeToSave = {
      start: startPoint
        ? {
            name: startInput,
            coordinates: startPoint,
          }
        : null,
      end: endPoint
        ? {
            name: endInput,
            coordinates: endPoint,
          }
        : null,
      waypoints: waypoints.map((coords) => ({
        coordinates: coords,
      })),
      configuration,
      company,
    };

    createSavedRoute.mutate(routeToSave);
  }, [startInput, endInput, startPoint, endPoint, waypoints, geojsonArray]);

  const handleUpdateSaveRoute = useCallback(async () => {
    const routeToUpdate = {
      _id: routeData?._id,
      start: startPoint
        ? {
            name: startInput,
            coordinates: startPoint,
          }
        : null,
      end: endPoint
        ? {
            name: endInput,
            coordinates: endPoint,
          }
        : null,
      waypoints: waypoints.map((coords) => ({
        coordinates: coords,
      })),
      configuration,
      company,
    };

    updateSavedRoute.mutate(routeToUpdate);
  }, [startInput, endInput, startPoint, endPoint, waypoints, geojsonArray]);

  useEffect(() => {
    const fetchRouteDistance = async () => {
      // Ensure start & end points exist.
      if (!startPoint || !endPoint) {
        setTotalDistance(0);
        return;
      }

      // Build the coordinates string for the URL. Include waypoints if available.
      // Format: startLng,startLat;waypoint1Lng,waypoint1Lat;...;endLng,endLat
      const coords: string[] = [];
      coords.push(`${startPoint[0]},${startPoint[1]}`);
      waypoints.forEach((pt) => {
        coords.push(`${pt[0]},${pt[1]}`);
      });
      coords.push(`${endPoint[0]},${endPoint[1]}`);

      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coords.join(
        ';',
      )}?access_token=${accessToken}&geometries=geojson`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data?.routes && data.routes.length > 0) {
          const distanceKm = data.routes[0].distance / 1000;
          setTotalDistance(distanceKm);
          setEstimatedDuration(data.routes[0].duration);
        } else {
          setTotalDistance(0);
        }
      } catch (error) {
        console.error('Error fetching route from Mapbox:', error);
        setTotalDistance(0);
      }
    };

    fetchRouteDistance();
  }, [startPoint, endPoint, waypoints]);

  useEffect(() => {
    const fetchTurnInstructions = async () => {
      if (!startPoint || !endPoint) {
        setTurnInstructions([]);
        return;
      }

      const coords: string[] = [];
      coords.push(`${startPoint[0]},${startPoint[1]}`);
      waypoints.forEach((pt) => {
        coords.push(`${pt[0]},${pt[1]}`);
      });
      coords.push(`${endPoint[0]},${endPoint[1]}`);

      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coords.join(
        ';',
      )}?access_token=${accessToken}&geometries=geojson&steps=true`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data?.routes && data.routes.length > 0) {
          const instructions: string[] = [];
          data.routes[0].legs.forEach((leg: any) => {
            leg.steps.forEach((step: any) => {
              if (step.maneuver && step.maneuver.instruction) {
                instructions.push(step.maneuver.instruction);
              }
            });
          });
          setTurnInstructions(instructions);
        }
      } catch (error) {
        console.error('Error fetching turn instructions:', error);
      }
    };

    fetchTurnInstructions();
  }, [startPoint, endPoint, waypoints]);

  const handleDownloadPDF = () => {
    // todo: probably just need to do this once instead...
    jsPDF.API.events.push(['addFonts', callAddFont]);
    jsPDF.API.events.push(['addFonts', callAddBoldFont]);
    jsPDF.API.events.push(['addFonts', callAddItalicFont]);

    const doc = new jsPDF({
      unit: 'mm',
      format: 'a4',
    });

    const headingFontSize = 16;
    doc.setFont('Roboto-Bold', 'bold');
    doc.setFontSize(headingFontSize);

    let currentY = 20;
    const margin = 10;
    const headingLineHeight = 1 * headingFontSize;

    doc.text(`Permit Pilot - Route Export`, margin, currentY);
    currentY += headingLineHeight;

    const detailsFontSize = 12;
    doc.setFont('Roboto-Italic', 'italic');
    doc.setFontSize(detailsFontSize);

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const effectiveWidth = pageWidth - 2 * margin;
    const detaiLineHeight = 1 * detailsFontSize;

    const currentConfiguration = configurations?.find(
      (c) => c._id === configuration,
    );

    const vehicle = vehicles?.find(
      (v) => currentConfiguration?.vehicle === v._id,
    );

    doc.text(
      vehicle ? `Vehicle: #${vehicle?.rego} - ${vehicle?.fleetNumber}` : 'N/A',
      margin,
      currentY,
    );
    currentY += detaiLineHeight;
    doc.text(
      `Configuration: ${currentConfiguration?.name || 'N/A'}`,
      margin,
      currentY,
    );
    currentY += detaiLineHeight;
    doc.text(`Start: ${startInput}`, margin, currentY);
    currentY += detaiLineHeight;
    doc.text(`End: ${endInput}`, margin, currentY);
    currentY += detaiLineHeight;
    doc.text(
      `Total Distance: ${totalDistance.toFixed(2)} km`,
      margin,
      currentY,
    );
    currentY += detaiLineHeight;

    let durationText = '';
    if (estimatedDuration >= 3600) {
      const hours = Math.floor(estimatedDuration / 3600);
      const remainingMinutes = Math.floor((estimatedDuration % 3600) / 60);
      const remainingSeconds = Math.floor(estimatedDuration % 60);

      durationText = `${hours} ${hours === 1 ? 'hour' : 'hours'}`;

      if (remainingMinutes > 0 || remainingSeconds > 0) {
        durationText += ` ${remainingMinutes} ${remainingMinutes === 1 ? 'min' : 'mins'}`;
      }

      if (remainingSeconds > 0) {
        durationText += ` ${remainingSeconds} ${remainingSeconds === 1 ? 'sec' : 'secs'}`;
      }
    } else {
      const minutes = Math.floor(estimatedDuration / 60);
      const seconds = Math.floor(estimatedDuration % 60);

      durationText = `${minutes} ${minutes === 1 ? 'min' : 'mins'}`;

      if (seconds > 0) {
        durationText += ` ${seconds} ${seconds === 1 ? 'sec' : 'secs'}`;
      }
    }
    doc.text(`Estimated Duration: ${durationText}`, margin, currentY);
    currentY += detaiLineHeight + 2.5;

    const instructionsFontSize = 12;
    doc.setFontSize(instructionsFontSize);
    doc.setFont('Roboto-Regular', 'normal');
    const instructionsLineHeight = 0.5 * instructionsFontSize;

    turnInstructions.forEach((instruction, idx) => {
      const instructionText = `${idx + 1}. ${instruction}`;
      const multiLines = doc.splitTextToSize(instructionText, effectiveWidth);

      if (
        currentY + multiLines.length * instructionsLineHeight >
        pageHeight - margin
      ) {
        doc.addPage();
        currentY = 20;
      }

      doc.text(multiLines, margin, currentY);
      currentY += multiLines.length * instructionsLineHeight + 5;
    });

    doc.save('turn_instructions.pdf');
  };

  const isSavedRoutesPage = routeName === 'Saved Routes';

  if (isLoading) {
    return (
      <Box>
        <CircularProgress size="small" />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
        marginTop={2}
        gap={2}
        sx={{
          position: 'absolute',
          zIndex: '1',
          marginTop: '20px',
          width: displayLeftBar ? '60%' : '60%',
          paddingLeft: displayLeftBar ? '20px' : '18%',
        }}
      >
        <Autocomplete<Suggestion>
          options={startSuggestions}
          getOptionLabel={(option) => option.place_name}
          filterOptions={(x) => x} // disables default filtering
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={startInput}
          value={startValue}
          onInputChange={(_event, value) => {
            setStartInput(value);
            if (value.length > 2) {
              debouncedFetchSuggestionsStart(value);
            }
          }}
          onChange={(_event: any, value: Suggestion | null) => {
            if (value) {
              setStartPoint(value.center);
            } else {
              setStartPoint(null);
            }
            setStartValue(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Box component="div" display="flex" justifyContent="start">
                  <StartLocationIcon />
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '14px', fontWeight: 600, ml: 2 }}
                  >
                    Start Location
                  </Typography>
                </Box>
              }
              variant="filled"
              fullWidth
            />
          )}
          style={{ width: '45%' }}
        />

        <IconButton
          sx={{ marginTop: '25px' }}
          onClick={() => {
            const tempStartPoint = startPoint;
            const tempStartInput = startInput;
            const tempStartValue = startValue;

            setStartPoint(endPoint);
            setStartInput(endInput);
            setStartValue(endValue);

            setEndPoint(tempStartPoint);
            setEndInput(tempStartInput);
            setEndValue(tempStartValue);
          }}
        >
          <SwapIcon />
        </IconButton>

        <Autocomplete<Suggestion>
          options={endSuggestions}
          getOptionLabel={(option) => option.place_name}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          inputValue={endInput}
          value={endValue}
          onInputChange={(_event, value) => {
            setEndInput(value);
            if (value.length > 2) {
              debouncedFetchSuggestionsEnd(value);
            }
          }}
          onChange={(_event: any, value: Suggestion | null) => {
            if (value) {
              setEndPoint(value.center);
            } else {
              setEndPoint(null);
            }
            setEndValue(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Box component="div" display="flex" justifyContent="start">
                  <EndLocationIcon />
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '14px', fontWeight: 600, ml: 2 }}
                  >
                    End Location
                  </Typography>
                </Box>
              }
              variant="filled"
              fullWidth
            />
          )}
          style={{ width: '45%' }}
        />

        {startPoint && endPoint && (
          <Box sx={{ position: 'absolute', top: '80vh', left: '45%' }}>
            <Typography variant="h6" sx={{ color: '#fff' }}>
              Distance: {totalDistance.toFixed(2)} km
            </Typography>
          </Box>
        )}

        <Fab
          variant="circular"
          onClick={switchLeftBar}
          sx={{
            backgroundColor: '#121212',
            position: 'absolute',
            left: '0px',
            top: '40vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 1,
            borderRadius: 0,
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
            '&:hover': {
              backgroundColor: '#121212',
              boxShadow: 'none',
            },
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: '#222',
              borderRadius: '50%',
              width: '45px',
              height: '45px',
            }}
          >
            {displayLeftBar && <ArrowAltLeftIcon color="#F6AE2D" />}
            {!displayLeftBar && <ArrowAltRightIcon color="#F6AE2D" />}
          </Box>
        </Fab>

        <Fab
          variant="circular"
          onClick={toggleWaypointMode}
          sx={{
            backgroundColor: 'primary.main',
            position: 'absolute',
            left: '10px',
            top: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <WaypointMarker
              style={
                isWaypointMode ? { position: 'relative', left: '4px' } : {}
              }
            />
            {isWaypointMode ? (
              <Typography
                variant="caption"
                style={{
                  color: '#000',
                  fontSize: '16px',
                  marginTop: '-25px',
                  textAlign: 'center',
                }}
              >
                +
              </Typography>
            ) : null}
          </Box>
        </Fab>

        <Fab
          variant="circular"
          onClick={() => setShowHighRiskOnly(!showHighRiskOnly)}
          sx={{
            backgroundColor: 'secondary',
            position: 'absolute',
            left: '10px',
            top: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <HidePointsIcon
              circleStroke={showHighRiskOnly ? 'white' : 'black'}
            />
          </Box>
        </Fab>

        <Fab
          variant="circular"
          onClick={routeData?._id ? handleUpdateSaveRoute : handleSaveRoute}
          sx={{
            backgroundColor: 'primary.main',
            position: 'absolute',
            left: '10px',
            top: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SaveIcon />
        </Fab>

        {isSavedRoutesPage ? (
          <Fab
            variant="circular"
            onClick={handleDownloadPDF}
            sx={{
              backgroundColor: 'secondary.main',
              '&:hover': {
                backgroundColor: 'secondary.main',
              },
              position: 'absolute',
              right: '10px',
              top: '70vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="caption" color="white">
              PDF
            </Typography>
          </Fab>
        ) : null}
      </Box>

      <CustomMap
        geojsonData={geojsonArray}
        tilesetIds={tilesetIds}
        rcaTilesetIds={rcaTilesetIds}
        startPoint={startPoint}
        endPoint={endPoint}
        waypoints={waypoints}
        setWaypoints={setWaypoints}
        isWaypointMode={isWaypointMode}
        showHighRiskOnly={showHighRiskOnly}
        displayLeftBar={displayLeftBar}
      />
    </Box>
  );
};

export default MapWithAutocomplete;

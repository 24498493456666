import SaveIcon from '@/components/icons/SaveIcon';
import { createConfigurationSchema } from '@/lib/validation/configurations';
import {
  Box,
  Button,
  darken,
  Divider,
  Fab,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  styled,
  Typography,
  Autocomplete,
  CircularProgress,
  Chip,
} from '@mui/material';
import { Field, FieldArray, Formik, FormikHelpers, FormikProps } from 'formik';
import {
  CurrentFiles,
  FileDropZone,
  PDatePicker,
  PTextField,
  SnackBarContext,
} from 'pragmatic-ui';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useCreateConfiguration } from '@/hooks/admin/useConfigurations';
import { useContext } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { TYPE_OPTIONS } from '@/constants';
import CloseIcon from '@mui/icons-material/Close';
import DatePickerIcon from '@/components/icons/DatePickerIcon';
import { useNZTA } from '@/hooks/admin/useNzta';

const StyledForm = styled('form')(() => ({
  width: '100%',
}));

const FileRender = ({
  file,
  onRemoveFileClick,
}: {
  file: CurrentFiles;
  onStarClick: () => void;
  onRemoveFileClick: () => void;
  removeEntry?: boolean;
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" color="primary" sx={{ mr: 4 }}>
          {file.filename}
        </Typography>
        <Button onClick={onRemoveFileClick}>Remove File</Button>
      </Box>
    </>
  );
};

export default function CreateConfigurationForm({
  vehicleId,
}: {
  vehicleId: string;
}) {
  const navigate = useNavigate();
  const { showSnack } = useContext(SnackBarContext);
  const createConfiguration = useCreateConfiguration();
  const { data: nztaData, isLoading } = useNZTA();

  if (isLoading) {
    return (
      <Box>
        <CircularProgress size="small" />
      </Box>
    );
  }

  const handleSubmit = async (
    formValues: any,
    { resetForm }: FormikHelpers<any>,
  ) => {
    //console.log('formValues', formValues);
    const formData = new FormData();

    formData.append('vehicle', formValues.vehicle);
    formData.append('name', formValues.name);
    formData.append('multiUnit', formValues.multiUnit.toString());

    const appendFilesToFormData = (permits: any[], formData: FormData) => {
      permits.forEach((permit: any, uploadIndex: number) => {
        const fileKey = `files-${uploadIndex}`;
        const files = formValues[fileKey] || [];

        files.forEach((file: any) => {
          const fileid = uuidV4();
          permit.fileids = permit.fileids || [];
          if (!permit.fileids.includes(fileid)) {
            permit.fileids.push(fileid);
            formData.append(fileid, file, file.name);
          }
        });
      });
    };

    formValues.forms.forEach((form: any) => {
      appendFilesToFormData(form.permits, formData);
    });

    formData.append('uploads', JSON.stringify(formValues.forms[0].permits));

    try {
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }
      createConfiguration.mutate(formData);
      resetForm();
      showSnack('Configuration submitted: file upload in progress...', 'info');
      navigate(`/admin/vehicles/${vehicleId}`); // Adjust the path as needed
    } catch (error) {
      console.error('Error creating configuration:', error);
      showSnack('Error creating configuration', 'error');
    }
  };

  const formikProps = {
    initialValues: {
      vehicle: vehicleId,
      name: '',
      multiUnit: false,
      forms: [
        {
          permits: [
            {
              rca: '',
              type: '',
              issueDate: new Date(),
              expiryDate: new Date(),
            },
          ],
        },
      ],
    },
    validationSchema: createConfigurationSchema,
    onSubmit: handleSubmit,
  };

  return (
    <Box px={4}>
      <Formik {...formikProps}>
        {({ handleSubmit, setFieldValue, values }: FormikProps<any>) => {
          return (
            <>
              <StyledForm onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display={'flex'} alignItems="center">
                    <PTextField
                      variant="standard"
                      autoComplete="off"
                      name={'name'}
                      placeholder="Configuration Name"
                      InputProps={{
                        disableUnderline: false,
                      }}
                      sx={{
                        width: '300px',
                      }}
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Button
                      size="small"
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        mr: 2,
                      }}
                      onClick={() => navigate(`/admin/vehicles/${vehicleId}`)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      color="primary"
                      startIcon={<SaveIcon />}
                      sx={{ textTransform: 'uppercase', fontWeight: '700' }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
                <>
                  <FieldArray name="forms">
                    {() => (
                      <>
                        {values.forms.map((form: any, index: number) => (
                          <Box py={4} key={index}>
                            <Grid container columnSpacing={8}>
                              <FieldArray name={`forms.${index}.permits`}>
                                {({
                                  remove: removePermit,
                                  push: pushPermit,
                                }: any) => (
                                  <>
                                    <Grid
                                      item
                                      xs={9}
                                      mt={8}
                                      key={`permits-${index}`}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{ color: '#EAEAEA' }}
                                      >
                                        Attach Permits
                                      </Typography>
                                      <Paper
                                        component="div"
                                        sx={{
                                          py: 3,
                                          pl: 2,
                                          pr: 0,
                                          mt: 4,
                                          borderRadius: '16px',
                                        }}
                                      >
                                        {form.permits?.map(
                                          (permit: any, idx: number) => (
                                            <Grid
                                              container
                                              columnSpacing={4}
                                              mt={idx > 0 ? 0 : 0}
                                              key={`permit-${idx}-${idx}`}
                                            >
                                              <Grid item xs={2}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.rca`}
                                                >
                                                  {({ field, form }: any) => (
                                                    <Autocomplete
                                                      options={nztaData || []}
                                                      getOptionLabel={(
                                                        option,
                                                      ) => option.name}
                                                      onError={(error) => {
                                                        console.log(
                                                          'error',
                                                          error,
                                                        );
                                                      }}
                                                      value={
                                                        nztaData?.find(
                                                          (nzta) =>
                                                            nzta._id ===
                                                            field?.value,
                                                        ) || null
                                                      }
                                                      onChange={(_, value) => {
                                                        form.setFieldValue(
                                                          `forms.${index}.permits.${idx}.rca`,
                                                          value?._id || null,
                                                        );

                                                        form.setFieldValue(
                                                          `forms.${index}.permits.${idx}.councils`,
                                                          [],
                                                        );
                                                      }}
                                                      renderInput={(params) => (
                                                        <PTextField
                                                          {...params}
                                                          {...field}
                                                          label={
                                                            idx === 0
                                                              ? 'RCA'
                                                              : ''
                                                          }
                                                          placeholder="RCA"
                                                          fullWidth
                                                        />
                                                      )}
                                                    />
                                                  )}
                                                </Field>
                                              </Grid>

                                              <Grid item xs={3}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.councils`}
                                                >
                                                  {({ field, form }: any) => {
                                                    const selectedRcaId =
                                                      form.values.forms[index]
                                                        .permits[idx].rca;
                                                    console.log(
                                                      'selected RCA id',
                                                    );
                                                    const selectedRca =
                                                      nztaData?.find(
                                                        (nzta) =>
                                                          nzta._id ===
                                                          selectedRcaId,
                                                      );
                                                    const availableCouncils =
                                                      selectedRca?.councils ||
                                                      [];

                                                    return (
                                                      <Autocomplete
                                                        multiple
                                                        options={
                                                          availableCouncils
                                                        }
                                                        getOptionLabel={(
                                                          option,
                                                        ) => option.name}
                                                        isOptionEqualToValue={(
                                                          option,
                                                          value,
                                                        ) =>
                                                          option._id ===
                                                          value._id
                                                        }
                                                        value={
                                                          field.value || []
                                                        }
                                                        onChange={(
                                                          _,
                                                          newValue,
                                                        ) => {
                                                          form.setFieldValue(
                                                            `forms.${index}.permits.${idx}.councils`,
                                                            newValue,
                                                          );
                                                        }}
                                                        renderInput={(
                                                          params,
                                                        ) => (
                                                          <PTextField
                                                            {...params}
                                                            {...field}
                                                            label={
                                                              idx === 0
                                                                ? 'COUNCILS'
                                                                : ''
                                                            }
                                                            placeholder="Select Councils"
                                                            fullWidth
                                                          />
                                                        )}
                                                        renderTags={(
                                                          tagValue,
                                                          getTagProps,
                                                        ) =>
                                                          tagValue.map(
                                                            (option, index) => (
                                                              <Chip
                                                                label={
                                                                  option.name
                                                                }
                                                                {...getTagProps(
                                                                  { index },
                                                                )}
                                                                key={option._id}
                                                              />
                                                            ),
                                                          )
                                                        }
                                                        disabled={
                                                          !selectedRcaId
                                                        }
                                                      />
                                                    );
                                                  }}
                                                </Field>
                                              </Grid>

                                              <Grid item xs={2}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.type`}
                                                >
                                                  {({ field }: any) => (
                                                    <PTextField
                                                      {...field}
                                                      label={idx == 0 && 'TYPE'}
                                                      placeholder="Type"
                                                      fullWidth
                                                      select
                                                    >
                                                      {TYPE_OPTIONS.map(
                                                        (type) => (
                                                          <MenuItem
                                                            key={`type-${type}`}
                                                            value={type}
                                                          >
                                                            {type}
                                                          </MenuItem>
                                                        ),
                                                      )}
                                                    </PTextField>
                                                  )}
                                                </Field>
                                              </Grid>

                                              <Grid item xs={2}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.issueDate`}
                                                >
                                                  {({
                                                    field,
                                                  }: {
                                                    field: {
                                                      name: string;
                                                      value: Date;
                                                      onChange: (
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                      ) => void;
                                                      onBlur: (
                                                        event: React.FocusEvent<HTMLInputElement>,
                                                      ) => void;
                                                    };
                                                  }) => (
                                                    <LocalizationProvider
                                                      dateAdapter={
                                                        AdapterDateFns
                                                      }
                                                    >
                                                      <DesktopDatePicker
                                                        name={`forms.${index}.permits.${idx}.issueDate`}
                                                        value={field.value}
                                                        onChange={(date) =>
                                                          setFieldValue(
                                                            field.name,
                                                            date,
                                                          )
                                                        }
                                                        label={
                                                          idx == 0 && 'ISSUED'
                                                        }
                                                        format="dd/MM/yyyy"
                                                        slots={{
                                                          openPickerIcon:
                                                            DatePickerIcon,
                                                        }}
                                                      />
                                                    </LocalizationProvider>
                                                  )}
                                                </Field>
                                              </Grid>

                                              <Grid item xs={2}>
                                                <Field
                                                  name={`forms.${index}.permits.${idx}.expiryDate`}
                                                >
                                                  {({
                                                    field,
                                                  }: {
                                                    field: {
                                                      name: string;
                                                      value: Date;
                                                      onChange: (
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                      ) => void;
                                                      onBlur: (
                                                        event: React.FocusEvent<HTMLInputElement>,
                                                      ) => void;
                                                    };
                                                  }) => (
                                                    <LocalizationProvider
                                                      dateAdapter={
                                                        AdapterDateFns
                                                      }
                                                    >
                                                      <DesktopDatePicker
                                                        name={`forms.${index}.permits.${idx}.expiryDate`}
                                                        value={field.value}
                                                        onChange={(date) =>
                                                          setFieldValue(
                                                            field.name,
                                                            date,
                                                          )
                                                        }
                                                        label={
                                                          idx == 0 && 'EXPIRY'
                                                        }
                                                        format="dd/MM/yyyy"
                                                        slots={{
                                                          openPickerIcon:
                                                            DatePickerIcon,
                                                        }}
                                                      />
                                                    </LocalizationProvider>
                                                  )}
                                                </Field>
                                              </Grid>

                                              <Grid item xs={3}>
                                                <InputLabel
                                                  sx={{ color: '#fff' }}
                                                >
                                                  {idx == 0 && 'UPLOADS'}
                                                </InputLabel>
                                                <FileDropZone
                                                  name={`files-${idx}`}
                                                  maxFiles={20}
                                                  featured
                                                  fileFormat={{
                                                    'application/pdf': ['.pdf'],
                                                    'application/json': [
                                                      '.json',
                                                      '.geojson',
                                                    ],
                                                  }}
                                                  renderTitle={(
                                                    openFilePicker,
                                                  ) => (
                                                    <>
                                                      <Typography
                                                        variant="caption"
                                                        sx={{
                                                          fontSize: '14px',
                                                          ml: -7,
                                                        }}
                                                        onClick={() => {
                                                          if (openFilePicker) {
                                                            openFilePicker();
                                                          }
                                                        }}
                                                      >
                                                        Drag and drop to upload
                                                        (max file size: 50 MB)
                                                      </Typography>
                                                    </>
                                                  )}
                                                  renderButton={() => <></>}
                                                  renderFile={(
                                                    file,
                                                    onStarClick,
                                                    onRemoveFileClick,
                                                  ) => {
                                                    return (
                                                      <FileRender
                                                        file={file}
                                                        onStarClick={
                                                          onStarClick
                                                        }
                                                        onRemoveFileClick={
                                                          onRemoveFileClick
                                                        }
                                                      />
                                                    );
                                                  }}
                                                  containerSx={{
                                                    background: 'transparent',
                                                    px: 0,
                                                    mx: 0,
                                                  }}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={1}
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Fab
                                                  onClick={() => {
                                                    removePermit(idx);
                                                  }}
                                                  color="secondary"
                                                  size="small"
                                                  sx={{
                                                    mr: 2,
                                                    '&:hover': {
                                                      backgroundColor: darken(
                                                        '#F6AE2D',
                                                        0.2,
                                                      ),
                                                    },
                                                  }}
                                                >
                                                  <CloseIcon />
                                                </Fab>
                                              </Grid>
                                            </Grid>
                                          ),
                                        )}
                                      </Paper>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      display="flex"
                                      direction={'column'}
                                      justifyContent={'flex-end'}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignContent="center"
                                        alignItems="center"
                                      >
                                        <Fab
                                          color="primary"
                                          size="small"
                                          sx={{
                                            mr: 2,
                                            '&:hover': {
                                              backgroundColor: darken(
                                                '#F6AE2D',
                                                0.2,
                                              ),
                                            },
                                          }}
                                          onClick={() =>
                                            pushPermit({
                                              rca: '',
                                              type: '',
                                              issueDate: new Date(),
                                              expiryDate: new Date(),
                                            })
                                          }
                                        >
                                          <AddIcon />
                                        </Fab>
                                        <Typography sx={{ color: '#F6AE2D' }}>
                                          Add Another Permit
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </>
                                )}
                              </FieldArray>
                            </Grid>

                            <Grid container mt={8} columnSpacing={8}>
                              <Grid item xs={12}>
                                <Divider
                                  component="div"
                                  sx={{ backgroundColor: '#BD3825' }}
                                />
                              </Grid>
                              {/* {index === values.forms.length - 1 ? (
                                    <Grid item xs={1.5}>
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignContent="center"
                                        alignItems="center"
                                        mt={-3}
                                      >
                                        <Fab
                                          color="primary"
                                          size="small"
                                          sx={{
                                            mr: 2,
                                            '&:hover': {
                                              backgroundColor: darken(
                                                '#F6AE2D',
                                                0.2,
                                              ),
                                            },
                                          }}
                                          onClick={() => {
                                            push({
                                              rego: '',
                                              fleet_number: '',
                                              make: '',
                                              model: '',
                                              vehicle_type: '',
                                              uploads: [
                                                {
                                                  name: '',
                                                  description: '',
                                                },
                                              ],
                                            });
                                          }}
                                        >
                                          <AddIcon />
                                        </Fab>
                                        <Typography sx={{ color: '#F6AE2D' }}>
                                          Add New
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  ) : null} */}
                            </Grid>
                          </Box>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </>
              </StyledForm>
            </>
          );
        }}
      </Formik>
    </Box>
  );
}

import ErrorPage from '@/pages/admin/ErrorPage';
import Dashboard from '@/pages/admin/Dashboard';
import Vehicles from '@/pages/admin/Vehicles';
import VehiclesEdit from '@/pages/admin/VehiclesEdit';
import Teams from '@/pages/admin/Teams';
import TeamsCreate from '@/pages/admin/TeamsCreate';
import Profile from '@/pages/admin/Profile';
import Configurations from '@/pages/admin/Configurations';
import Permits from '@/pages/admin/Permits';
import type { Permissions, AvailableRole } from '@/types/types';
import ViewConfiguration from './pages/admin/ViewConfiguration';
import CustomerVehicles from '@/pages/Vehicles';
import CustomerVehicle from '@/pages/Vehicle';
import CustomerPermits from '@/pages/Permits';
import CustomerTeams from '@/pages/Teams';
import CustomerConfiguration from '@/pages/Configuration';
import CustomerProfile from '@/pages/Profile';
import SavedRoutes from './pages/admin/SavedRoutes';

export const availablePermissions = ['create', 'delete', 'read', 'update'];
export const permissions: Permissions = {
  canAddTeamMembers: true,
  canDeleteTeam: true,
  canRemoveTeamMembers: true,
  canUpdateTeam: true,
  canUpdateTeamMembers: true,
};
export const defaultPermissions = ['read'];

export const availableRoles: AvailableRole[] = [
  {
    description: 'Administrator users can perform any action.',
    key: 'admin',
    name: 'Admin',
    permissions: ['create', 'delete', 'read', 'update'],
  },
  {
    description: 'Editor users have the ability to read, create, and update.',
    key: 'editor',
    name: 'Editor',
    permissions: ['create', 'read', 'update'],
  },
  {
    description: 'View only users only have the ability to read',
    key: 'view-only',
    name: 'View Only',
    permissions: ['read'],
  },
];

export const customerAvailableRoles: AvailableRole[] = [
  {
    description: 'Customer application manager',
    key: 'manager',
    name: 'Manager',
    permissions: ['create', 'delete', 'read', 'update'],
  },
  {
    description: 'Customer application driver',
    key: 'driver',
    name: 'Driver',
    permissions: ['read'],
  },
];

export type PageRoute = {
  path: string;
  name: string;
  element: JSX.Element;
  errorElement: JSX.Element;
};

export const pageRoutes: PageRoute[] = [
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/vehicles',
    name: 'Vehicles',
    element: <Vehicles />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/vehicles/new',
    name: 'Add New Vehicle',
    element: <Vehicles />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/vehicles/:id',
    name: 'Edit Vehicle',
    element: <VehiclesEdit />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/teams',
    name: 'Team',
    element: <Teams />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/teams/create',
    name: 'Team Create',
    element: <TeamsCreate />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/profile',
    name: 'Profile',
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/configurations',
    name: 'Configurations',
    element: <Configurations />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/configurations/edit/:id',
    name: 'Edit Configuration',
    element: <Configurations />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/configurations/edit/:id/:permitId',
    name: 'Edit Configuration',
    element: <Configurations />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/vehicles/:id/configurations/new',
    name: 'Add new configuration',
    element: <Configurations />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/permits',
    name: 'Permits',
    element: <Permits />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/companies/:companyId/configurations/view',
    name: 'Route Map',
    element: <ViewConfiguration />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/companies/:companyId/configurations/view/:id',
    name: 'Route Map',
    element: <ViewConfiguration />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/dashboard',
    name: 'Vehicles',
    element: <CustomerVehicles />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    element: <CustomerVehicles />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/vehicles/:id',
    name: 'Documents',
    element: <CustomerVehicle />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/permits',
    name: 'Permits',
    element: <CustomerPermits />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/teams',
    name: 'Teams',
    element: <CustomerTeams />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/configurations',
    name: 'Route Planning',
    element: <CustomerConfiguration />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/profile',
    name: 'Profile',
    element: <CustomerProfile />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin/companies/:companyId/configurations/:configurationId/saved-routes/:savedRouteId',
    name: 'Saved Routes',
    element: <SavedRoutes />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/companies/:companyId/configurations/:configurationId/saved-routes/:savedRouteId',
    name: 'Saved Routes',
    element: <SavedRoutes />,
    errorElement: <ErrorPage />,
  },
];

// this is temporary
export const TYPE_OPTIONS = ['Area', 'Continuous', 'Multi', 'Single', 'HPMV'];

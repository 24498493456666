import { useConfigurations } from '@/hooks/admin/useConfigurations';
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import MobileCraneIcon from '@/components/icons/MobileCraneIcon';
import ArrowDownIcon from '@/components/icons/ArrowDownIcon';
import { useEffect, useState } from 'react';
import { useVehicles } from '@/hooks/admin/useVehicles';
import { Vehicle } from '@/types/api';
import ArrowUpIcon from '@/components/icons/ArrowUpIcon';
import MapWithAutocomplete from '@/components/NewMapWithAutocomplete';
import MapWithoutGeojson from '@/components/MapWithoutGeojson';
import ManIcon from '@/components/icons/ManIcon';
import DocIcon from '@/components/icons/DocIcon';
import RouteIcon from '@/components/icons/RouteIcon';
import SaveRouteMapIcon from '@/components/icons/SaveRouteMapIcon';
import ArrowLeftIcon from '@/components/icons/ArrowAltLeftIcon';
import { format } from 'date-fns';
import { useNZTA } from '@/hooks/admin/useNzta';

export default function ViewConfiguration() {
  const navigate = useNavigate();
  const { companyId, configurationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { data: configurations, isLoading: isConfigurationsLoading } =
    useConfigurations();
  const { data: nztaData, isLoading: isNztaDataLoading } = useNZTA();
  const { data: vehicles, isLoading: isVehiclesLoading } = useVehicles();
  const isLoading =
    isConfigurationsLoading || isVehiclesLoading || isNztaDataLoading;

  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(
    () => location.state?.selectedVehicle ?? null,
  );
  const initialOpenedVehicleId = searchParams.get('openedVehicleId');
  const [openedVehicleId, setOpenedVehicleId] = useState<string | null>(
    initialOpenedVehicleId,
  );
  const [displayLeftBar, setDisplayLeftBar] = useState<boolean>(true);

  const filteredVehicles =
    vehicles?.filter((v) => v.company._id === companyId) ?? [];

  const [displayVehicles, setDisplayVehicles] = useState<boolean>(true);
  const [displaySavedRoutes, setDisplaySavedRoutes] = useState<boolean>(false);
  const [displayRoutes] = useState<any>(null);
  const [selectedSavedRoute, setSelectedSavedRoute] = useState<any>(null);

  useEffect(() => {
    if (selectedVehicle && configurations && !configurationId) {
      const configurationsForVehicle = configurations.filter(
        (c) => c.vehicle === selectedVehicle._id,
      );
      if (configurationsForVehicle.length > 0) {
        navigate(
          `/admin/companies/${companyId}/configurations/view/${configurationsForVehicle[0]._id}?openedVehicleId=${openedVehicleId || ''}`,
          {
            state: {
              selectedVehicle,
              displayLeftBar,
              displayVehicles,
              displaySavedRoutes,
              selectedSavedRoute,
            },
          },
        );
      }
    }
  }, [
    selectedVehicle,
    configurations,
    configurationId,
    companyId,
    navigate,
    openedVehicleId,
    displayLeftBar,
    displayVehicles,
    displaySavedRoutes,
    selectedSavedRoute,
  ]);

  const selectedConfiguration =
    configurations?.find((c) => c._id === configurationId) || null;

  const onClickSwitchLeftBar = () => {
    setDisplayLeftBar(!displayLeftBar);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const onClickRoute = () => {
    setDisplayVehicles(false);
    navigate(
      `/admin/companies/${companyId}/configurations/${configurationId}/saved-routes`,
    );
  };

  const onClickVehicle = () => {
    setDisplayVehicles(true);
    setDisplaySavedRoutes(false);
    setSelectedSavedRoute(null);
    navigate(
      `/admin/companies/${companyId}/configurations/view/${configurationId}?openedVehicleId=${openedVehicleId || ''}`,
      {
        state: {
          selectedVehicle,
          displayLeftBar,
          displayVehicles,
          displaySavedRoutes,
          selectedSavedRoute: null,
        },
      },
    );
  };

  let route: any[] =
    selectedConfiguration?.permits.map((permit: any) => permit.route) || [];

  const councilIds: any[] =
    selectedConfiguration?.permits
      .filter((permit: any) => permit?.councils)
      .map((permit: any) => permit.councils)
      .flat() || [];

  const selectedConfigurationRcas = selectedConfiguration?.permits.map(
    (permit) => permit.rca,
  );

  const councils: any[] =
    nztaData
      ?.filter((nzta) => selectedConfigurationRcas?.find((s) => s === nzta._id))
      .map((nzta) => nzta.councils)
      .flat()
      .filter((council) => councilIds.find((c) => c._id === council._id))
      .map((c) => c.geojson) || [];

  route = [...route, ...councils];

  return (
    <Box>
      <Grid container spacing={1}>
        {displayLeftBar && (
          <Grid
            item
            xs={displayLeftBar ? 4 : 0}
            sx={{
              height: '100vh',
              overflowY: 'auto',
            }}
          >
            {displayVehicles && (
              <Box>
                <Box
                  px={3}
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  sx={{ marginTop: '-10px' }}
                >
                  <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                    VEHICLE CONFIGURATIONS
                  </Typography>
                </Box>
                {filteredVehicles.map((vehicle) => {
                  // todo: extract this into another component
                  const configurationsForVehicle = configurations?.filter(
                    (c) => c.vehicle === vehicle._id,
                  );

                  const isVehicleSelected =
                    selectedVehicle?._id === vehicle._id;
                  const isVehicleOpened = openedVehicleId === vehicle._id;

                  return (
                    <Paper
                      key={vehicle._id}
                      sx={{
                        width: '99%',
                        background: '#334657',
                        borderRadius: '6px',
                        border: `0.5px solid ${isVehicleSelected ? '#F6AE2D' : 'transparent'}`,
                        paddingY: '15px',
                        mt: 1,
                        ml: 1,
                        cursor: 'pointer',
                      }}
                    >
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={2}
                        onClick={() => {
                          setSelectedVehicle(vehicle);
                          setOpenedVehicleId(vehicle._id);
                          searchParams.set('openedVehicleId', vehicle._id);
                          setSearchParams(searchParams);
                          const configurationsForVehicle =
                            configurations?.filter(
                              (c) => c.vehicle === vehicle._id,
                            );
                          if (
                            configurationsForVehicle &&
                            configurationsForVehicle.length > 0
                          ) {
                            const defaultConfig = configurationsForVehicle[0];
                            navigate(
                              `/admin/companies/${companyId}/configurations/view/${defaultConfig._id}?openedVehicleId=${vehicle._id}`,
                              {
                                state: {
                                  selectedVehicle: vehicle,
                                  displayLeftBar,
                                  displayVehicles,
                                  displaySavedRoutes,
                                  selectedSavedRoute,
                                },
                              },
                            );
                          }
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <MobileCraneIcon />
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexGrow={1}
                          ml={2}
                        >
                          <Chip
                            sx={{
                              background: '#fff',
                              borderRadius: '4px',
                              width: '100px',
                            }}
                            label={
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  color: '#202D38',
                                }}
                              >
                                {vehicle.rego || 'N/A'}
                              </Typography>
                            }
                          />
                          <Typography sx={{ ml: 2, fontSize: '12px' }}>
                            FLEET: #{vehicle.fleetNumber || 'N/A'}{' '}
                          </Typography>
                        </Box>

                        {/* Right Icon */}
                        <Box
                          display="flex"
                          alignItems="center"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenedVehicleId(
                              isVehicleOpened ? null : vehicle._id,
                            );
                          }}
                        >
                          {isVehicleOpened ? (
                            <ArrowUpIcon />
                          ) : (
                            <ArrowDownIcon />
                          )}
                        </Box>
                      </Box>

                      {/* Configurations Dropdown */}
                      {isVehicleOpened &&
                        configurationsForVehicle &&
                        configurationsForVehicle.length > 0 && (
                          <>
                            <Box px={2} mt={2}>
                              <Select
                                variant="filled"
                                fullWidth
                                value={configurationId || ''}
                                onChange={(e) => {
                                  const config = configurationsForVehicle.find(
                                    (c) => c._id === e.target.value,
                                  );
                                  setSelectedSavedRoute(null);
                                  navigate(
                                    `/admin/companies/${companyId}/configurations/view/${e.target.value}?openedVehicleId=${openedVehicleId || ''}`,
                                  );
                                }}
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    color: 'primary.main', // Replace with your desired color
                                  },
                                }}
                              >
                                {configurationsForVehicle.map((config) => (
                                  <MenuItem key={config._id} value={config._id}>
                                    {config.name ||
                                      `Configuration ${config._id}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                            {selectedConfiguration &&
                              openedVehicleId ==
                                selectedConfiguration.vehicle && (
                                <Box
                                  px={2}
                                  mt={2}
                                  pt={1}
                                  pb={1}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-evenly"
                                >
                                  <IconButton onClick={() => {}}>
                                    <ManIcon />
                                  </IconButton>
                                  <IconButton onClick={() => {}}>
                                    <DocIcon />
                                  </IconButton>
                                  <IconButton onClick={onClickRoute}>
                                    <RouteIcon />
                                  </IconButton>
                                  <IconButton onClick={() => {}}>
                                    <SaveRouteMapIcon />
                                  </IconButton>
                                </Box>
                              )}
                          </>
                        )}
                    </Paper>
                  );
                })}
              </Box>
            )}
            {displaySavedRoutes && (
              <Box>
                <Box
                  px={3}
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  sx={{ marginTop: '-10px' }}
                >
                  <IconButton onClick={onClickVehicle}>
                    <ArrowLeftIcon />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                      textAlign: 'center',
                      marginLeft: '2px',
                    }}
                  >
                    {selectedVehicle?.rego}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      textAlign: 'center',
                      backgroundColor: '#444444',
                      color: '#fff',
                      borderRadius: '12px',
                      padding: '1px 10px',
                      marginLeft: '10px',
                    }}
                  >
                    {selectedConfiguration?.name}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    marginTop: '10px',
                    marginLeft: '30px',
                    marginRight: '10px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                    SAVED ROUTES
                  </Typography>
                </Box>
                {displayRoutes?.map((route: any) => (
                  <Box
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    sx={{
                      marginTop: '10px',
                      marginLeft: '6px',
                      background: '#334657',
                      borderRadius: '6px',
                    }}
                    onClick={() => setSelectedSavedRoute(route)}
                  >
                    <Box
                      sx={{
                        padding: '10px 0 10px 10px',
                        width: '40%',
                      }}
                    >
                      <Typography sx={{ fontSize: '12px', textAlign: 'left' }}>
                        {/* STEVE THOMASON */}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', textAlign: 'left' }}>
                        {format(
                          new Date(route.updatedAt),
                          'hhmma, dd-MMM-yyyy',
                        ).toUpperCase()}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '10px 0',
                        width: '60%',
                        marginLeft: '5px',
                      }}
                    >
                      <Typography sx={{ fontSize: '13px', textAlign: 'left' }}>
                        {route.start.name} - {route.end.name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={displayLeftBar ? 8 : 12}
          sx={{ paddingTop: 0, marginTop: '-20px' }}
        >
          {route ? (
            <MapWithAutocomplete
              routeData={{
                geojsonData: route,
                ...selectedSavedRoute,
              }}
              company={String(companyId)}
              configuration={selectedConfiguration?._id || ''}
              switchLeftBar={onClickSwitchLeftBar}
              displayLeftBar={displayLeftBar}
            />
          ) : (
            <MapWithoutGeojson
              switchLeftBar={onClickSwitchLeftBar}
              displayLeftBar={displayLeftBar}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

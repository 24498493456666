import '@/styles/app.css';
import AppProvider from '@/AppProvider';
import AppRouter from './routes/AppRouter';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '327459b191d98ec68e501be4100ef6e7Tz0xMDkwOTksRT0xNzcyODQxNTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

export default function App() {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

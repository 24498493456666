import { useState } from 'react';
import { Drawer, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, darken } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateCompanyForm from '@/layouts/CreateCompanyForm';

export default function CreateClientDrawer() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (
    event:
      | React.KeyboardEvent
      | React.MouseEvent
      | React.SyntheticEvent<Element, Event>,
    open: boolean,
  ) => {
    if (
      event.type === 'keydown' &&
      'key' in event && // Add this condition to ensure 'key' exists
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        onClick={(e) => toggleDrawer(e, true)}
        sx={{
          '&:hover': {
            backgroundColor: darken('#F6AE2D', 0.2),
          },
        }}
      >
        ADD NEW
      </Button>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={(e: React.SyntheticEvent) => toggleDrawer(e, false)}
        PaperProps={{
          sx: {
            width: '30% !important',
            background: '#202D38',
            borderRadius: '12px 0px 0px 12px !important',
            color: '#fff',
          },
        }}
      >
        <div role="presentation">
          <Box
            component="div"
            display="flex"
            m={3}
            justifyContent="start"
            alignItems={'center'}
            onClick={(e) => toggleDrawer(e, false)}
          >
            <ArrowBackIcon sx={{ cursor: 'pointer', mr: 3 }}></ArrowBackIcon>
            <Typography variant="h6" sx={{ color: '#fff' }}>
              Add New Client
            </Typography>
          </Box>
          <Box
            m={10}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: -1 }}
          >
            <CreateCompanyForm toggleDrawer={() => setDrawerOpen(!open)} />
          </Box>
        </div>
      </Drawer>
    </div>
  );
}

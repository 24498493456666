export default function SaveRouteMapIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.625 5L3.625 3V19L9.625 21M9.625 5L15.625 3M9.625 5V21M15.625 3L21.625 5V21L15.625 19M15.625 3V19M15.625 19L9.625 21"
        stroke="#F6AE2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { useCompanies } from '@/hooks/admin/useCompanies';
import { useConfigurations } from '@/hooks/admin/useConfigurations';
import { useVehicles } from '@/hooks/admin/useVehicles';
import { isDateExpiring } from '@/lib/dates';
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';

const StyledButton = styled(Button)(() => ({
  borderRadius: '8px',
  border: '1px solid  #F26419',
  backgroundColor: '#BD3825 !important',
  color: '#fff',
  textTransform: 'uppercase',
  padding: '7px 10px',
  boxShadow: 'none',
  marginLeft: 2,
  fontSize: '12px',
  fontWeight: '600',
}));

const StyledTypography = styled(Typography)(() => ({
  fontSize: '16px',
  marginLeft: 1,
  fontWeight: '700',
}));

export default function AdminStats() {
  const { data: companies, isLoading: isCompaniesLoading } = useCompanies();
  const { data: vehicles, isLoading: isVehiclesLoading } = useVehicles();
  const { data: configurations, isLoading: isConfigurationsLoading } =
    useConfigurations();

  const isLoading =
    isCompaniesLoading && isVehiclesLoading && isConfigurationsLoading;

  if (isLoading) {
    return (
      <Box>
        <CircularProgress size="small" />
      </Box>
    );
  }

  const companiesCount = companies?.length;
  const vehiclesCount = vehicles?.length;
  const upcomingExpiredPermitsCount =
    configurations
      ?.flatMap(({ permits }) => permits)
      .filter(({ expiryDate }) => isDateExpiring(new Date(expiryDate)))
      .length ?? 0;

  return (
    <Box>
      <StyledButton variant="contained">
        Client total:{' '}
        <StyledTypography sx={{ fontSize: '16px', marginLeft: 1 }}>
          {companiesCount}
        </StyledTypography>
      </StyledButton>
      <StyledButton variant="contained" sx={{ marginLeft: 2 }}>
        Vehicle total:
        <StyledTypography sx={{ fontSize: '16px', marginLeft: 1 }}>
          {vehiclesCount}
        </StyledTypography>
      </StyledButton>
      <StyledButton variant="contained" sx={{ marginLeft: 2 }}>
        Upcoming Expired Permit Total:
        <StyledTypography sx={{ fontSize: '16px', marginLeft: 1 }}>
          {upcomingExpiredPermitsCount}
        </StyledTypography>
      </StyledButton>
    </Box>
  );
}

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import AppRootCustomer from './AppRootCutomer';
import AppRootAdmin from '@/routes/AppRootAdmin';
import Login from '@/pages/admin/auth/Login';
import ForgotPassword from '@/pages/admin/auth/ForgotPassword';
import ResetPassword from '@/pages/admin/auth/ResetPassword';
import ErrorPage from '@/pages/admin/ErrorPage';
import {
  ProtectedAdminRoute,
  ProtectedCustomerRoute,
} from '@/hooks/auth/useAuth';
import Configurations from '@/pages/admin/Configurations';
import Profile from '@/pages/admin/Profile';
import TeamsCreate from '@/pages/admin/TeamsCreate';
import Teams from '@/pages/admin/Teams';
import VehiclesEdit from '@/pages/admin/VehiclesEdit';
import Vehicles from '@/pages/admin/Vehicles';
import Dashboard from '@/pages/admin/Dashboard';
import Permits from '@/pages/admin/Permits';
import PublicRoute from '@/PublicRoute';
import CreateConfiguration from '@/pages/admin/CreateConfiguration';
import EditConfiguration from '@/pages/admin/EditConfiguration';
import GlobalLoadingIndicator from '@/components/GlobalLoadingIndicator';
import ViewConfiguration from '@/pages/admin/ViewConfiguration';

import CustomerLogin from '@/pages/auth/Login';
//import CustomerDashboard from '@/pages/Dashboard';
import CustomerVehicles from '@/pages/Vehicles';
import CustomerVehicle from '@/pages/Vehicle';
import CustomerPermits from '@/pages/Permits';
import CustomerTeams from '@/pages/Teams';
import CustomerConfigurations from '@/pages/Configuration';
import CustomerInvite from '@/pages/auth/Invite';
import CustomerErrorPage from '@/pages/ErrorPage';
import CustomerProfile from '@/pages/Profile';
import CustomerForgotPassword from '@/pages/auth/ForgotPassword';
import CustomerReset from '@/pages/auth/Reset';
import SavedRoutes from '@/pages/admin/SavedRoutes';

export type PageRoute = {
  path: string;
  name: string;
  element: JSX.Element;
  errorElement: JSX.Element;
};

export default function AppRouter() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Navigate to="/login" replace />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/admin/',
      element: <Navigate to="/admin/login" replace />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/login',
      element: (
        <PublicRoute>
          <CustomerLogin />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: 'auth/forgot-password',
      element: (
        <PublicRoute>
          <CustomerForgotPassword />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: 'auth/reset/:resetToken',
      element: (
        <PublicRoute>
          <CustomerReset />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/invite',
      element: (
        <PublicRoute>
          <CustomerInvite />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/error',
      element: (
        <PublicRoute>
          <CustomerErrorPage />
        </PublicRoute>
      ),
      errorElement: <CustomerErrorPage />,
    },
    {
      path: 'admin/login',
      element: (
        <PublicRoute>
          <Login />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: 'admin/forgot-password',
      element: (
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: 'admin/reset-password',
      element: (
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      ),
      errorElement: <ErrorPage />,
    },
    {
      element: (
        <ProtectedAdminRoute>
          <AppRootAdmin />
        </ProtectedAdminRoute>
      ),
      children: [
        {
          path: '/admin/dashboard',
          element: <Dashboard />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/companies/:companyId/configurations/view',
          element: <ViewConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/companies/:companyId/configurations/view/:configurationId',
          element: <ViewConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/vehicles',
          element: <Vehicles />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/vehicles/:vehicleId',
          element: <VehiclesEdit />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/vehicles/new',
          element: <Configurations />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/vehicles/:vehicleId/configurations/new',
          element: <CreateConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/configurations/view/:configurationId',
          element: <ViewConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/configurations/edit/:configurationId',
          element: <EditConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/configurations/edit/:configurationId/:permitId',
          element: <EditConfiguration />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/teams',
          element: <Teams />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/teams/create',
          element: <TeamsCreate />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/profile',
          element: <Profile />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/permits',
          element: <Permits />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/admin/companies/:companyId/configurations/:configurationId/saved-routes',
          element: <SavedRoutes />,
          errorElement: <ErrorPage />
        },
        {
          path: '/admin/companies/:companyId/configurations/:configurationId/saved-routes/:savedRouteId',
          element: <SavedRoutes />,
          errorElement: <ErrorPage />
        },
      ],
    },
    {
      element: (
        <ProtectedCustomerRoute>
          <AppRootCustomer />
        </ProtectedCustomerRoute>
      ),
      children: [
        {
          path: '/dashboard',
          element: <CustomerVehicles />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/configurations',
          element: <CustomerConfigurations />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/vehicles',
          element: <CustomerVehicles />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/vehicles/:vehicleId',
          element: <CustomerVehicle />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/permits',
          element: <CustomerPermits />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/teams',
          element: <CustomerTeams />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/profile',
          element: <CustomerProfile />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return (
    <>
      <GlobalLoadingIndicator />
      <RouterProvider router={router} />
    </>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Box } from '@mui/material';
import bbox from '@turf/bbox';

// todo: fix type checking on this file asap!

interface CustomMapProps {
  geojsonData?: GeoJSON.FeatureCollection;
  startPoint?: [number, number] | null;
  endPoint?: [number, number] | null;
  waypoints: [number, number][];
  setWaypoints: React.Dispatch<React.SetStateAction<[number, number][]>>;
  isWaypointMode: boolean;
  showHighRiskOnly: boolean;
  displayLeftBar: boolean;
}

const markerStyles = {
  startMarker: {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    display: 'block',
  },
  endMarker: {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    display: 'block',
  },
};

const CustomMap: React.FC<CustomMapProps> = ({
  geojsonData,
  startPoint = null,
  endPoint = null,
  waypoints = [],
  setWaypoints,
  isWaypointMode,
  showHighRiskOnly,
  displayLeftBar,
}) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const waypointMarkersRef = useRef<mapboxgl.Marker[]>([]);
  const [shouldFitBounds, setShouldFitBounds] = useState(true);
  const startMarkerRef = useRef(null);
  const endMarkerRef = useRef(null);

  // Replace with your actual Mapbox access token
  mapboxgl.accessToken =
    'pk.eyJ1Ijoib3JjaGlkd2ViIiwiYSI6ImNtOG1jcWM5cjBiNzYycXBtbWhzMXI5YW0ifQ.tu_IuquB3UhBfXataJpe4w';

  // Initialize the map once
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current ?? '',
      style: 'mapbox://styles/mapbox/dark-v11',
      center: [174.76555, -36.84846],
      zoom: 8,
    });

    mapRef.current = map;

    map.on('load', () => {
      // Map is fully loaded here
      // You can add any initial layers or sources if needed
    });

    // Clean up on unmount
    return () => {
      map.remove();
    };
  }, []);

  // Add GeoJSON data source and points layer
  useEffect(() => {
    const map = mapRef.current;

    const addDataLayer = () => {
      if (!mapRef.current?.getSource('geojson-data')) {
        // Add GeoJSON data source
        mapRef.current?.addSource('geojson-data', {
          type: 'geojson',
          data: geojsonData,
        });

        // Add points layer
        mapRef.current?.addLayer({
          id: 'points-layer',
          type: 'circle',
          source: 'geojson-data',
          paint: {
            'circle-radius': 10,
            'circle-stroke-width': 4,
            'circle-stroke-color': '#F44336',
            'circle-color': [
              'case',
              ['==', ['downcase', ['get', 'position']], 'do not cross'],
              '#FF4500',
              [
                'match',
                ['downcase', ['get', 'risk']],
                'not significant',
                '#FFF',
                'low',
                '#FFF',
                /* default if none match */
                '#FFF',
              ],
            ],
          },
        });

        mapRef.current?.addLayer({
          id: 'points-numbers',
          type: 'symbol',
          source: 'geojson-data',
          layout: {
            'text-field': [
              'case',
              ['==', ['downcase', ['get', 'position']], 'do not cross'],
              // If position matches "do not cross"
              '—',
              // Otherwise, check risk (Not Significant/Low/else)
              [
                'match',
                ['downcase', ['get', 'risk']],
                'not significant',
                ['get', 'speed'],
                'low',
                ['get', 'speed'],
                ['get', 'speed'], // default
              ],
            ],
            'text-size': [
              'case',
              ['==', ['downcase', ['get', 'position']], 'do not cross'],
              // Size for "do not cross"
              14,
              // Default for everything else
              12,
            ],
            'text-font': [
              'case',
              ['==', ['downcase', ['get', 'position']], 'do not cross'],
              // Bold font for "do not cross"
              ['DIN Pro Bold', 'Arial Unicode MS Bold'],
              // Default regular font for everything else
              ['DIN Pro Medium', 'Arial Unicode MS Regular'],
            ],
            'text-allow-overlap': true,
            'text-ignore-placement': true,
            'text-anchor': 'center',
          },
          paint: {
            'text-color': [
              'case',
              ['==', ['downcase', ['get', 'position']], 'do not cross'],
              '#FFFFFF', // White text if position is "do not cross"
              '#000000', // Black text for all other cases
            ],
          },
        });

        // Add navigation control
        mapRef.current?.addControl(
          new mapboxgl.NavigationControl(),
          'bottom-right',
        );

        // Event listeners for points
        mapRef.current?.on('click', 'points-layer', (e: any) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const properties = e.features[0].properties;
          new mapboxgl.Popup({ className: 'custom-popup' })
            .setLngLat(coordinates)
            .setHTML(
              `<div>
                <h3 style="width: 85%; margin-bottom: 5px; font-size: 16px">${properties.bridgename}</h3>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Owner:</strong> ${properties.owner}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Area:</strong> ${properties.area}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Risk:</strong> ${properties.risk}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Permit number:</strong> ${properties.permit}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Expiry:</strong> ${properties.expiry}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Speed:</strong> ${properties.speed} km/hr</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Position:</strong> ${properties.position}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Coordinates:</strong> ${properties.coordinates}</p>
                <p style="font-size: 15px; margin-bottom: 5px"><strong>Notes:</strong> ${properties.notes}</p>
                <p style="font-size: 15px"><strong>VAI:</strong> ${properties.vai}</p>
              </div>`,
            )
            .addTo(map);
        });

        mapRef.current?.on('mouseenter', 'points-layer', () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'points-layer', () => {
          map.getCanvas().style.cursor = '';
        });
      }
    };

    if (map.isStyleLoaded()) {
      addDataLayer();
    } else {
      map.once('styledata', addDataLayer);
    }
  }, [geojsonData]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    if (map.isStyleLoaded()) {
      if (showHighRiskOnly) {
        map.setFilter('points-layer', [
          '==',
          ['downcase', ['get', 'position']],
          'do not cross',
        ]);
        map.setFilter('points-numbers', [
          '==',
          ['downcase', ['get', 'position']],
          'do not cross',
        ]);
      } else {
        map.setFilter('points-layer', null);
        map.setFilter('points-numbers', null);
      }
    } else {
      map.on('load', () => {
        if (showHighRiskOnly) {
          map.setFilter('points-layer', [
            '==',
            ['downcase', ['get', 'position']],
            'do not cross',
          ]);
          map.setFilter('points-numbers', [
            '==',
            ['downcase', ['get', 'position']],
            'do not cross',
          ]);
        } else {
          map.setFilter('points-layer', null);
          map.setFilter('points-numbers', null);
        }
      });
    }
  }, [showHighRiskOnly]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const handleMapClick = (e: mapboxgl.MapMouseEvent) => {
      if (!isWaypointMode) return;

      const newWaypoint: [number, number] = [e.lngLat.lng, e.lngLat.lat];
      setWaypoints((prev) => [...prev, newWaypoint]);
    };

    map.on('click', handleMapClick);

    return () => {
      map.off('click', handleMapClick);
    };
  }, [isWaypointMode, setWaypoints]);

  // Add waypoint markers
  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    // Remove existing waypoint markers
    if (map.getLayer('waypoints')) map.removeLayer('waypoints');
    if (map.getSource('waypoints')) map.removeSource('waypoints');

    // Add waypoint markers
    if (waypoints.length > 0) {
      map.addSource('waypoints', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: waypoints.map((waypoint, index) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: waypoint,
            },
            properties: {
              id: `waypoint-${index}`,
            },
          })),
        },
      });

      map.addLayer({
        id: 'waypoints',
        type: 'circle',
        source: 'waypoints',
        paint: {
          'circle-radius': 6,
          'circle-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            '#FFA500', // Hover color (orange)
            '#FFD700', // Default color (gold)
          ],
          'circle-opacity': 0.8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000000',
          'circle-stroke-opacity': 0.5,
        },
      });
    }
  }, [waypoints]);

  // Handle route fetching and rendering
  useEffect(() => {
    const map = mapRef.current;
    if (!startPoint || !endPoint || !map) return;

    const fetchRoute = async () => {
      const coordinates = [startPoint, ...waypoints, endPoint];
      const coordinatesStr = coordinates
        .map((coord) => coord.join(','))
        .join(';');

      const directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinatesStr}?geometries=geojson&&overview=full&steps=true&access_token=${mapboxgl.accessToken}`;

      try {
        const response = await fetch(directionsUrl);
        const data = await response.json();
        if (!data.routes || !data.routes.length) {
          console.error('No routes found.');
          return;
        }
        const route = data.routes[0].geometry;

        const routeGeoJSON: GeoJSON.Feature = {
          type: 'Feature',
          properties: {},
          geometry: route,
        };

        const addRouteLayer = () => {
          // Add or update the route source and layer
          if (map.getSource('route')) {
            (map.getSource('route') as mapboxgl.GeoJSONSource).setData(
              routeGeoJSON,
            );
          } else {
            map.addSource('route', {
              type: 'geojson',
              data: routeGeoJSON,
            });

            map.addLayer({
              id: 'route-layer',
              type: 'line',
              source: 'route',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': 'rgb(83, 143, 223)',
                'line-width': 4,
              },
            });
          }

          // Fit the map to the route
          const [minLng, minLat, maxLng, maxLat] = bbox(routeGeoJSON);
          if (shouldFitBounds) {
            map.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: 100, // Increase padding
                minZoom: map.getZoom(), // Don't zoom in further than current level
                maxZoom: map.getZoom(),
              },
            );
            setShouldFitBounds(false);
          }
        };

        if (map.isStyleLoaded()) {
          addRouteLayer();
        } else {
          map.once('styledata', addRouteLayer);
        }
      } catch (error) {
        console.error('Error fetching route:', error);
      }
    };

    fetchRoute();
  }, [startPoint, endPoint, waypoints]);

  // Add start, end, and waypoint markers
  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const updateMarkers = () => {
      // Remove existing markers
      if (startMarkerRef.current) {
        startMarkerRef.current.remove();
        startMarkerRef.current = null;
      }
      if (endMarkerRef.current) {
        endMarkerRef.current.remove();
        endMarkerRef.current = null;
      }

      // Remove existing waypoint markers
      waypointMarkersRef.current.forEach((marker) => marker.remove());
      waypointMarkersRef.current = [];

      // Add start marker
      if (startPoint) {
        const startEl = document.createElement('div');
        startEl.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <circle opacity="0.3" cx="32" cy="32" r="31.5" fill="#3C91E6" stroke="#3C91E6"/>
            <g transform="translate(21, 21) scale(1)">
              <path d="M11 21.2663C16.7906 21.2663 21.5 16.6522 21.5 10.9417C21.5 5.23134 16.7906 0.617188 11 0.617188C5.20939 0.617188 0.5 5.23134 0.5 10.9417C0.5 16.6522 5.20939 21.2663 11 21.2663ZM16.9286 10.9417C16.9286 14.0529 14.3411 16.6949 11 16.6949C7.65894 16.6949 5.07143 14.0529 5.07143 10.9417C5.07143 7.83061 7.65894 5.18862 11 5.18862C14.3411 5.18862 16.9286 7.83061 16.9286 10.9417Z" fill="#C2EFEB" stroke="#C2EFEB"/>
            </g>
          </svg>`;

        Object.assign(startEl.style, {
          ...markerStyles.startMarker,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        });

        startMarkerRef.current = new mapboxgl.Marker({
          element: startEl,
          anchor: 'center',
        })
          .setLngLat(startPoint)
          .addTo(map);
      }

      // Add end marker
      if (endPoint) {
        const endEl = document.createElement('div');
        endEl.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2127 25.0763C17.0127 22.7847 23.8783 16.6799 23.8783 11.9168C23.8783 5.93374 19.0281 1.0835 13.045 1.0835C7.06192 1.0835 2.21167 5.93374 2.21167 11.9168C2.21167 16.6799 9.07732 22.7847 11.8773 25.0763C12.5635 25.638 13.5265 25.638 14.2127 25.0763ZM13.045 15.1668C14.8399 15.1668 16.295 13.7118 16.295 11.9168C16.295 10.1219 14.8399 8.66683 13.045 8.66683C11.2501 8.66683 9.795 10.1219 9.795 11.9168C9.795 13.7118 11.2501 15.1668 13.045 15.1668Z" fill="#F6AE2D"/>
          </svg>`;

        Object.assign(endEl.style, {
          ...markerStyles.startMarker,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        });

        endMarkerRef.current = new mapboxgl.Marker({
          element: endEl,
          anchor: 'center',
        })
          .setLngLat(endPoint)
          .addTo(map);
      }

      // Add waypoint markers
      waypoints.forEach((waypoint, index) => {
        const marker = new mapboxgl.Marker({
          color: 'rgb(246, 174, 45)',
          draggable: true,
        })
          .setLngLat(waypoint)
          .addTo(map);

        marker.on('dragend', () => {
          const lngLat = marker.getLngLat();
          setWaypoints((prevWaypoints) => {
            const newWaypoints = [...prevWaypoints];
            newWaypoints[index] = [lngLat.lng, lngLat.lat];
            return newWaypoints;
          });
        });

        marker.getElement().addEventListener('dblclick', (e) => {
          e.stopPropagation();
          setWaypoints((prevWaypoints) => {
            const newWaypoints = [...prevWaypoints];
            newWaypoints.splice(index, 1);
            return newWaypoints;
          });
          marker.remove();
        });

        waypointMarkersRef.current.push(marker);
      });
    };

    if (map.isStyleLoaded()) {
      updateMarkers();
    } else {
      map.once('styledata', updateMarkers);
    }

    return () => {
      if (startMarkerRef.current) startMarkerRef?.current?.remove();
      if (endMarkerRef.current) endMarkerRef?.current?.remove();
      waypointMarkersRef.current.forEach((marker) => marker.remove());
      waypointMarkersRef.current = [];
    };
  }, [startPoint, endPoint, waypoints]);

  // Resize map on left bar display change
  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;
    map.resize();
  }, [displayLeftBar]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        component="div"
        ref={mapContainerRef}
        style={{ width: '100%', height: '100vh' }}
      />
    </Box>
  );
};

export default CustomMap;

import api from '@/lib/api-client';
import { nzta } from '@/types/api';
import { UseQueryOptions, QueryKey, useQuery } from '@tanstack/react-query';

const nztaKey = ['nzta'];

const getNZTAData = async (): Promise<nzta[]> => {
  const response = await api.get('/v1/admin/nzta');
  return response.data;
};

export const useNZTA = (
  options?: Omit<
    UseQueryOptions<nzta[], Error, nzta[], QueryKey>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryKey: nztaKey,
    queryFn: getNZTAData,
    staleTime: Infinity, // Never mark the data as stale
    ...options,
  });

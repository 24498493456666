import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { Box } from '@mui/material';
import bbox from '@turf/bbox';

interface CustomMapProps {
  /**
   * An array of GeoJSON FeatureCollections representing different geojson datasets
   * that you want to display on the map.
   */
  geojsonData: GeoJSON.FeatureCollection[];

  /**
   * An array of Mapbox tilesetIds to render on the map.
   * These will be used instead of GeoJSON for NZTA regions/councils.
   */
  tilesetIds: string[];

  /**
   * An array of Mapbox tilesetIds to render on the map.
   * These will be used instead of GeoJSON for NZTA regions.
   */
  rcaTilesetIds: string[];

  /**
   * Optional start point (longitude, latitude) for a route.
   */
  startPoint?: [number, number] | null;

  /**
   * Optional end point (longitude, latitude) for a route.
   */
  endPoint?: [number, number] | null;

  /**
   * Any waypoints (longitude, latitude) in between start and end.
   */
  waypoints: [number, number][];

  /**
   * Setter for updating the array of waypoints (e.g., when user drags a waypoint).
   */
  setWaypoints: React.Dispatch<React.SetStateAction<[number, number][]>>;

  /**
   * Whether the map is in "waypoint mode" (clicks add new waypoints).
   */
  isWaypointMode: boolean;

  /**
   * Whether or not to show only "High Risk" or "Do Not Cross" points.
   * (Your code checks for position === "do not cross".)
   */
  showHighRiskOnly: boolean;

  /**
   * Whether the left sidebar is displayed, for resizing the map accordingly.
   */
  displayLeftBar: boolean;
}

const markerStyles = {
  startMarker: {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    display: 'block',
  },
  endMarker: {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    display: 'block',
  },
};

const CustomMap: React.FC<CustomMapProps> = ({
  geojsonData,
  tilesetIds,
  rcaTilesetIds,
  startPoint = null,
  endPoint = null,
  waypoints = [],
  setWaypoints,
  isWaypointMode,
  showHighRiskOnly,
  displayLeftBar,
}) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const waypointMarkersRef = useRef<mapboxgl.Marker[]>([]);
  const [localWaypoints, setLocalWaypoints] =
    useState<[number, number][]>(waypoints);
  const [mapStyle, _setMapStyle] = useState(
    'mapbox://styles/orchidweb/cm8v6wi7d00io01sb57j2c8nq',
  );

  useEffect(() => {
    setLocalWaypoints(waypoints);
  }, [waypoints]);

  const [shouldFitBounds, setShouldFitBounds] = useState(false);

  const startMarkerRef = useRef<mapboxgl.Marker | null>(null);
  const endMarkerRef = useRef<mapboxgl.Marker | null>(null);

  mapboxgl.accessToken =
    'pk.eyJ1Ijoib3JjaGlkd2ViIiwiYSI6ImNtOG1jcWM5cjBiNzYycXBtbWhzMXI5YW0ifQ.tu_IuquB3UhBfXataJpe4w';

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current ?? '',
      style: mapStyle,
      center: [174.76555, -36.84846],
      zoom: 8,
    });

    mapRef.current = map;

    mapRef.current?.addControl(
      new mapboxgl.NavigationControl(),
      'bottom-right',
    );

    return () => {
      map.remove();
    };
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const addTilesetLayers = () => {
      tilesetIds.forEach((_id, index) => {
        const sourceId = `tileset-source-${index}`;
        const layerId = `tileset-layer-${index}`;
        const outlineLayerId = `tileset-outline-${index}`;

        if (map.getLayer(layerId)) map.removeLayer(layerId);
        if (map.getLayer(outlineLayerId)) map.removeLayer(outlineLayerId);
        if (map.getSource(sourceId)) map.removeSource(sourceId);
      });

      map.setLayoutProperty('northland-0yeco9', 'visibility', 'none'); // todo: perhaps we can put this somewhere else?

      tilesetIds.forEach((tilesetId, index) => {
        const sourceId = `tileset-source-${index}`;
        const layerId = `tileset-layer-${index}`;
        const outlineLayerId = `tileset-outline-${index}`;

        if (!map.getSource(sourceId)) {
          map.addSource(sourceId, {
            type: 'vector',
            url: `mapbox://${tilesetId}`,
          });
        }

        const specialSourceLayerCases: Record<string, string> = {
          'orchidweb.3r6sn3ot': 'NZTA_Waikato___Bay_of_Plenty_-cx5j3o',
          'orchidweb.53qz71o4': 'NZTA_Wellington_Masterton_Dis-5uwk5f',
        };

        if (!map.getLayer(layerId)) {
          map.addLayer({
            id: layerId,
            type: 'fill',
            source: sourceId,
            'source-layer': specialSourceLayerCases[tilesetId] || 'data', // special case looking up is required
            paint: {
              'fill-color': 'rgba(246, 174, 45, 0.2)',
              'fill-opacity': 0.4,
            },
          });
        }

        if (!map.getLayer(outlineLayerId)) {
          map.addLayer({
            id: outlineLayerId,
            type: 'line',
            source: sourceId,
            'source-layer': specialSourceLayerCases[tilesetId] || 'data',
            paint: {
              'line-color': 'rgba(246, 174, 45, 0.8)',
              'line-width': 2,
            },
          });

          map.on('mouseenter', layerId, () => {
            map.getCanvas().style.cursor = 'pointer';
          });

          map.on('mouseleave', layerId, () => {
            map.getCanvas().style.cursor = '';
          });
        }
      });
    };

    if (map.isStyleLoaded()) {
      addTilesetLayers();
    } else {
      map.once('style.load', addTilesetLayers);
    }

    return () => {
      map.off('load', addTilesetLayers);
    };
  }, [tilesetIds, mapStyle]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const addRcaTilesetLayers = () => {
      rcaTilesetIds.forEach((_id, index) => {
        const sourceId = `rca-tileset-source-${index}`;
        const layerId = `rca-tileset-layer-${index}`;
        const outlineLayerId = `rca-tileset-outline-${index}`;

        if (map.getLayer(layerId)) map.removeLayer(layerId);
        if (map.getLayer(outlineLayerId)) map.removeLayer(outlineLayerId);
        if (map.getSource(sourceId)) map.removeSource(sourceId);
      });

      rcaTilesetIds.forEach((tilesetId, index) => {
        const sourceId = `rca-tileset-source-${index}`;
        const layerId = `rca-tileset-layer-${index}`;
        const outlineLayerId = `rca-tileset-outline-${index}`;

        if (!map.getSource(sourceId)) {
          map.addSource(sourceId, {
            type: 'vector',
            url: `mapbox://${tilesetId}`,
          });
        }

        if (!map.getLayer(layerId)) {
          map.addLayer({
            id: layerId,
            type: 'fill',
            source: sourceId,
            'source-layer': 'data',
            paint: {
              'fill-color': 'rgba(180, 160, 220, 0.2)',
              'fill-opacity': 0.2,
            },
          });
        }

        if (!map.getLayer(outlineLayerId)) {
          map.addLayer({
            id: outlineLayerId,
            type: 'line',
            source: sourceId,
            'source-layer': 'data',
            paint: {
              'line-color': 'rgba(180, 160, 220, 0.8)',
              'line-width': 2,
            },
          });

          map.on('mouseenter', layerId, () => {
            map.getCanvas().style.cursor = 'pointer';
          });

          map.on('mouseleave', layerId, () => {
            map.getCanvas().style.cursor = '';
          });
        }
      });
    };

    if (map.isStyleLoaded()) {
      addRcaTilesetLayers();
    } else {
      map.once('style.load', addRcaTilesetLayers);
    }

    return () => {
      map.off('load', addRcaTilesetLayers);
    };
  }, [rcaTilesetIds, mapStyle]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !startPoint || !endPoint) return;

    const fetchRoute = async () => {
      const allWaypoints = [...localWaypoints];
      const coords = [startPoint, ...allWaypoints, endPoint];
      const coordsString = coords.map((c) => c.join(',')).join(';');

      const directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordsString}?geometries=geojson&overview=full&steps=true&access_token=${mapboxgl.accessToken}`;

      try {
        const response = await fetch(directionsUrl);
        const data = await response.json();

        if (!data.routes || !data.routes.length) {
          console.error('No routes found.');
          return;
        }

        const route = data.routes[0].geometry;
        const routeGeoJSON: GeoJSON.Feature = {
          type: 'Feature',
          properties: {},
          geometry: route,
        };

        const addOrUpdateRouteLayer = () => {
          if (map.getSource('route')) {
            (map.getSource('route') as mapboxgl.GeoJSONSource).setData(
              routeGeoJSON,
            );
          } else {
            map.addSource('route', {
              type: 'geojson',
              data: routeGeoJSON,
            });

            map.addLayer({
              id: 'route-layer',
              type: 'line',
              source: 'route',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': 'rgb(83, 143, 223)',
                'line-width': 4,
              },
            });
          }

          const [minLng, minLat, maxLng, maxLat] = bbox(routeGeoJSON);
          if (shouldFitBounds) {
            map.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: 100,
                maxZoom: map.getZoom(),
              },
            );
            setShouldFitBounds(false);
          }
        };

        addOrUpdateRouteLayer();
      } catch (error) {
        console.error('Error fetching route:', error);
      }
    };

    if (map.isStyleLoaded()) {
      fetchRoute();
    } else {
      map.once('style.load', fetchRoute);
    }
  }, [startPoint, endPoint, localWaypoints, shouldFitBounds, mapStyle]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const cleanupExistingLayers = () => {
      const style = map.getStyle();
      if (style && style.layers) {
        const existingLayerIds = style.layers.map((layer) => layer.id);
        existingLayerIds.forEach((layerId) => {
          if (
            layerId.startsWith('points-layer-') ||
            layerId.startsWith('points-numbers-') ||
            layerId.startsWith('polygon-layer-')
          ) {
            if (map.getLayer(layerId)) {
              map.removeLayer(layerId);
            }
          }
        });
      }

      // Get existing sources and remove them if they match our pattern
      if (style && style.sources) {
        Object.keys(style.sources).forEach((sourceId) => {
          if (sourceId.startsWith('geojson-data-')) {
            if (map.getSource(sourceId)) {
              map.removeSource(sourceId);
            }
          }
        });
      }
    };

    const addOrUpdateDataLayers = () => {
      const pointFeatures: GeoJSON.Feature[] = [];

      geojsonData.forEach((item) => {
        if (!item) return;
        item.features.forEach((feature) => {
          pointFeatures.push(feature);
        });
      });

      if (pointFeatures.length > 0) {
        const pointsSourceId = 'geojson-data-points';

        if (!map.getSource(pointsSourceId)) {
          map.addSource(pointsSourceId, {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: pointFeatures,
            },
          });
        } else {
          (map.getSource(pointsSourceId) as mapboxgl.GeoJSONSource).setData({
            type: 'FeatureCollection',
            features: pointFeatures,
          });
        }

        // Add points layer
        const pointsLayerId = 'points-layer-main';
        if (!map.getLayer(pointsLayerId)) {
          map.addLayer({
            id: pointsLayerId,
            type: 'symbol',
            source: pointsSourceId,
            layout: {
              'icon-image': [
                'case',
                ['==', ['downcase', ['get', 'position']], 'do not cross'],
                'DNC-marker',
                ['==', ['get', 'speed'], 'N/A'],
                'warning2',
                [
                  'match',
                  ['to-number', ['get', 'speed'], 0],
                  0,
                  'DNC-marker',
                  10,
                  '10-marker',
                  20,
                  '20-marker',
                  30,
                  '30-marker',
                  50,
                  '50-marker',
                  90,
                  '90-marker',
                  'marker',
                ],
              ],
              'icon-allow-overlap': true,
              'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                5,
                0.4,
                12,
                0.8,
                14,
                1,
              ],
              'icon-anchor': [
                'case',
                ['==', ['get', 'speed'], 'N/A'],
                'top',
                'bottom',
              ],
              'icon-offset': [
                'case',
                ['==', ['get', 'speed'], 'N/A'],
                [0, 8],
                [0, 0],
              ],
            },
          });

          map.moveLayer(pointsLayerId);

          map.on('click', pointsLayerId, (e: any) => {
            if (e.features && e.features.length > 0) {
              const coordinates = e.features[0].geometry.coordinates.slice();
              const properties = e.features[0].properties;
              new mapboxgl.Popup({
                className: 'custom-popup',
                closeButton: true,
              })
                .setLngLat(coordinates)
                .setHTML(
                  `<div>
                    <h3 style="width: 85%; margin-bottom: 5px; font-size: 16px">${properties.bridgename || 'Point'}</h3>
                    ${properties.owner ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Owner:</strong> ${properties.owner}</p>` : ''}
                    ${properties.area ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Area:</strong> ${properties.area}</p>` : ''}
                    ${properties.risk ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Risk:</strong> ${properties.risk}</p>` : ''}
                    ${properties.permit ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Permit number:</strong> ${properties.permit}</p>` : ''}
                    ${properties.expiry ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Expiry:</strong> ${properties.expiry}</p>` : ''}
                    ${properties.speed ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Speed:</strong> ${properties.speed} km/hr</p>` : ''}
                    ${properties.position ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Position:</strong> ${properties.position}</p>` : ''}
                    ${properties.coordinates ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Coordinates:</strong> ${properties.coordinates}</p>` : ''}
                    ${properties.notes ? `<p style="font-size: 15px; margin-bottom: 5px"><strong>Notes:</strong> ${properties.notes}</p>` : ''}
                    ${properties.vai ? `<p style="font-size: 15px"><strong>VAI:</strong> ${properties.vai}</p>` : ''}
                  </div>`,
                )
                .addTo(map);
            }
          });

          map.on('mouseenter', pointsLayerId, () => {
            map.getCanvas().style.cursor = 'pointer';
          });

          map.on('mouseleave', pointsLayerId, () => {
            map.getCanvas().style.cursor = '';
          });
        }
      }
    };

    if (map.isStyleLoaded()) {
      cleanupExistingLayers();
      addOrUpdateDataLayers();
    } else {
      const setupMapOnce = () => {
        cleanupExistingLayers();
        addOrUpdateDataLayers();
        map.off('style.load', setupMapOnce);
      };
      map.on('style.load', setupMapOnce);
      map.once('styledata', addOrUpdateDataLayers);
    }

    return () => {
      map.off('style.load', addOrUpdateDataLayers);
    };
  }, [geojsonData, mapStyle]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const applyFilters = () => {
      geojsonData.forEach((_data) => {
        const pointsLayerId = `points-layer-main`;

        if (!map.getLayer(pointsLayerId)) {
          return;
        }

        if (showHighRiskOnly) {
          map.setFilter(pointsLayerId, [
            '==',
            ['downcase', ['get', 'position']],
            'do not cross',
          ]);
        } else {
          map.setFilter(pointsLayerId, null);
        }
      });
    };

    if (map.isStyleLoaded()) {
      applyFilters();
    } else {
      map.on('load', applyFilters);
    }
  }, [showHighRiskOnly, geojsonData, mapStyle]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const handleMapClick = (e: mapboxgl.MapMouseEvent) => {
      if (!isWaypointMode) return;
      const newWaypoint: [number, number] = [e.lngLat.lng, e.lngLat.lat];
      updateWaypoints((prev: any) => [...prev, newWaypoint]);
    };

    map.on('click', handleMapClick);
    return () => {
      map.off('click', handleMapClick);
    };
  }, [isWaypointMode]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    if (map.getLayer('waypoints')) map.removeLayer('waypoints');
    if (map.getSource('waypoints')) map.removeSource('waypoints');

    if (localWaypoints.length > 0) {
      map.addSource('waypoints', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: localWaypoints.map((coords, i) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: coords,
            },
            properties: {
              id: `waypoint-${i}`,
            },
          })),
        },
      });

      map.addLayer({
        id: 'waypoints',
        type: 'circle',
        source: 'waypoints',
        paint: {
          'circle-radius': 6,
          'circle-color': '#FFD700',
          'circle-opacity': 0.8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#000000',
          'circle-stroke-opacity': 0.5,
        },
      });
    }
  }, [localWaypoints, waypoints, mapStyle]);

  const updateWaypoints = (newWaypoints: any) => {
    setLocalWaypoints(newWaypoints);
    setWaypoints(newWaypoints);
  };

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const updateMarkers = () => {
      if (startMarkerRef.current) {
        startMarkerRef.current.remove();
        startMarkerRef.current = null;
      }
      if (endMarkerRef.current) {
        endMarkerRef.current.remove();
        endMarkerRef.current = null;
      }
      waypointMarkersRef.current.forEach((m) => m.remove());
      waypointMarkersRef.current = [];

      if (startPoint) {
        const startEl = document.createElement('div');
        startEl.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <circle opacity="0.3" cx="32" cy="32" r="31.5" fill="#3C91E6" stroke="#3C91E6"/>
            <g transform="translate(21, 21) scale(1)">
              <path d="M11 21.2663C16.7906 21.2663 21.5 16.6522 21.5 10.9417C21.5 5.23134 16.7906 0.617188 11 0.617188C5.20939 0.617188 0.5 5.23134 0.5 10.9417C0.5 16.6522 5.20939 21.2663 11 21.2663ZM16.9286 10.9417C16.9286 14.0529 14.3411 16.6949 11 16.6949C7.65894 16.6949 5.07143 14.0529 5.07143 10.9417C5.07143 7.83061 7.65894 5.18862 11 5.18862C14.3411 5.18862 16.9286 7.83061 16.9286 10.9417Z" fill="#C2EFEB" stroke="#C2EFEB"/>
            </g>
          </svg>`;

        Object.assign(startEl.style, {
          ...markerStyles.startMarker,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        });

        startMarkerRef.current = new mapboxgl.Marker({
          element: startEl,
          anchor: 'center',
        })
          .setLngLat(startPoint)
          .addTo(map);
      }

      // Add end marker
      if (endPoint) {
        const endEl = document.createElement('div');
        endEl.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2127 25.0763C17.0127 22.7847 23.8783 16.6799 23.8783 11.9168C23.8783 5.93374 19.0281 1.0835 13.045 1.0835C7.06192 1.0835 2.21167 5.93374 2.21167 11.9168C2.21167 16.6799 9.07732 22.7847 11.8773 25.0763C12.5635 25.638 13.5265 25.638 14.2127 25.0763ZM13.045 15.1668C14.8399 15.1668 16.295 13.7118 16.295 11.9168C16.295 10.1219 14.8399 8.66683 13.045 8.66683C11.2501 8.66683 9.795 10.1219 9.795 11.9168C9.795 13.7118 11.2501 15.1668 13.045 15.1668Z" fill="#F6AE2D"/>
          </svg>`;

        Object.assign(endEl.style, {
          ...markerStyles.endMarker,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        });

        endMarkerRef.current = new mapboxgl.Marker({
          element: endEl,
          anchor: 'center',
        })
          .setLngLat(endPoint)
          .addTo(map);
      }

      //     color: 'rgb(246, 174, 45)',
      //     draggable: true,
      //   })
      //     .setLngLat(coords)
      //     .addTo(map);

      //   marker.on('dragend', () => {
      //     const lngLat = marker.getLngLat();
      //     updateWaypoints((prev: any) => {
      //       const updated = [...prev];
      //       updated[index] = [lngLat.lng, lngLat.lat];
      //       return updated;
      //     });
      //   });

      //   // Double-click to remove waypoint
      //   marker.getElement().addEventListener('dblclick', (e) => {
      //     e.stopPropagation();
      //     updateWaypoints((prev: any) => {
      //       const updated = [...prev];
      //       updated.splice(index, 1);
      //       return updated;
      //     });
      //     marker.remove();
      //   });

      //   waypointMarkersRef.current.push(marker);
      // });
      // Add numbered waypoint markers
      [...localWaypoints].forEach((coords, index) => {
        const marker = new mapboxgl.Marker({
          color: 'rgb(246, 174, 45)',
          draggable: true,
        })
          .setLngLat(coords)
          .addTo(map);

        const markerEl = marker.getElement();
        markerEl.style.marginTop = '9px';
        const overlay = document.createElement('div');
        overlay.textContent = (index + 1).toString();
        Object.assign(overlay.style, {
          position: 'relative',
          top: '-38px',
          left: '0',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          fontSize: '12',
          color: '#000',
        });

        markerEl.appendChild(overlay);

        marker.on('dragend', () => {
          const lngLat = marker.getLngLat();
          updateWaypoints((prev: any) => {
            const updated = [...prev];
            updated[index] = [lngLat.lng, lngLat.lat];
            return updated;
          });
        });

        markerEl.addEventListener('dblclick', (e) => {
          e.stopPropagation();
          updateWaypoints((prev: any) => {
            const updated = [...prev];
            updated.splice(index, 1);
            return updated;
          });
          marker.remove();
        });

        waypointMarkersRef.current.push(marker);
      });
    };

    updateMarkers();

    return () => {
      if (startMarkerRef.current) {
        startMarkerRef.current.remove();
        startMarkerRef.current = null;
      }
      if (endMarkerRef.current) {
        endMarkerRef.current.remove();
        endMarkerRef.current = null;
      }
      waypointMarkersRef.current.forEach((m) => m.remove());
      waypointMarkersRef.current = [];
    };
  }, [startPoint, endPoint, waypoints, localWaypoints, mapStyle]);

  useEffect(() => {
    mapRef?.current?.resize();
  }, [displayLeftBar]);

  useEffect(() => {
    const map = mapRef.current;
    if (!map) return;

    const handleZoomChange = () => {
      // const currentZoom = map.getZoom();
      // if (currentZoom > 15) {
      //   if (map.getStyle()?.name !== 'Mapbox Satellite') {
      //     map.setStyle('mapbox://styles/mapbox/satellitev9');
      //     setMapStyle('mapbox://styles/mapbox/satellite-v9');
      //   }
      // } else {
      //   // Switch back to dark style if necessary
      //   // console.log(map.getStyle()?.name);
      //   if (map.getStyle()?.name !== 'TSL2-copy') {
      //     map.setStyle('mapbox://styles/orchidweb/cm8v6wi7d00io01sb57j2c8nq');
      //     setMapStyle('mapbox://styles/orchidweb/cm8v6wi7d00io01sb57j2c8nq');
      //   }
      // }
    };

    // Use the "zoomend" event so that the style switches after zoom completes.
    map.on('zoomend', handleZoomChange);

    // Clean up the event listener on unmount
    return () => {
      map.off('zoomend', handleZoomChange);
    };
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        component="div"
        ref={mapContainerRef}
        style={{ width: '100%', height: '100vh' }}
      />
    </Box>
  );
};

export default CustomMap;

import {
  Box,
  Chip,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
  IconButton,
} from '@mui/material';
import MobileCraneIcon from '@/components/icons/MobileCraneIcon';
import ArrowDownIcon from '@/components/icons/ArrowDownIcon';
import { useEffect, useState } from 'react';
import { Vehicle } from '@/types/api';
import ArrowUpIcon from '@/components/icons/ArrowUpIcon';
import MapWithAutocomplete from '@/components/NewMapWithAutocomplete';
import { useAuthUser } from '@/hooks/auth/useAuth';
import { useGetVehiclesByCompany } from '@/hooks/customer/useVehicles';
import { useGetConfigurationsByCompany } from '@/hooks/customer/useConfigurations';
import MapWithoutGeojson from '@/components/MapWithoutGeojson';
import ManIcon from '@/components/icons/ManIcon';
import DocIcon from '@/components/icons/DocIcon';
import RouteIcon from '@/components/icons/RouteIcon';
import SaveRouteMapIcon from '@/components/icons/SaveRouteMapIcon';
import ArrowLeftIcon from '@/components/icons/ArrowAltLeftIcon';
import { useSavedRoutes, useDeleteSavedRoute } from '@/hooks/admin/useSavedRoutes';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Configuration() {
  const { data: authUser } = useAuthUser();
  const company = authUser?.currentCompany || '0';
  const { data: configurations } = useGetConfigurationsByCompany(
    String(company),
  );
  const { data: vehicles = [] } = useGetVehiclesByCompany(String(company));
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const [selectedConfiguration, setSelectedConfiguration] = useState<any>(null);
  const [openedVehicleId, setOpenedVehicleId] = useState<string | null>(null);
  const [displayVehicles, setDisplayVehicles] = useState<boolean>(true);
  const [displaySavedRoutes, setDisplaySavedRoutes] = useState<boolean>(false);
  const [displayLeftBar, setDisplayLeftBar] = useState<boolean>(true);
  const { data: savedRoutes } = useSavedRoutes();
  const [displayRoutes, setDisplayRoutes] = useState<any>(null);
  const [selectedSavedRoute, setSelectedSavedRoute] = useState<any>(null);
  const deleteSavedRoute = useDeleteSavedRoute();

  useEffect(() => {
    if (selectedVehicle) {
      const configurationsForVehicle = configurations?.filter(
        (c) => c.vehicle === selectedVehicle._id,
      );
      if (configurationsForVehicle && configurationsForVehicle.length > 0) {
        setSelectedConfiguration(configurationsForVehicle[0]);
      } else {
        setSelectedConfiguration(null);
      }
    }
  }, [selectedVehicle, configurations]);

  useEffect(() => {
    setDisplayRoutes(
      savedRoutes?.filter(
        (route) => route.configuration === selectedConfiguration?._id,
      ),
    );
  }, [savedRoutes, selectedConfiguration]);

  const route = selectedConfiguration?.permits.map(
    (permit: any) => permit.route,
  );

  const onClickRoute = () => {
    setDisplayVehicles(false);
    setDisplaySavedRoutes(true);
    setDisplayRoutes(
      savedRoutes?.filter(
        (route) => route.configuration === selectedConfiguration?._id,
      ),
    );
  };

  const onClickVehicle = () => {
    setDisplayVehicles(true);
    setDisplaySavedRoutes(false);
  };

  const onClickSwitchLeftBar = () => {
    setDisplayLeftBar(!displayLeftBar);
  };

  const handleDelete = (id: string) => {
    deleteSavedRoute.mutate(id);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        {displayLeftBar && (
          <Grid item xs={displayLeftBar ? 4 : 0}
          sx={{
            height: '100vh',
            overflowY: 'auto',
          }}
          >
            {displayVehicles && (
              <Box>
                <Box
                  px={3}
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  sx={{ marginTop: '-10px' }}
                >
                  <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                    VEHICLE CONFIGURATIONS
                  </Typography>
                </Box>
                {vehicles.map((vehicle) => {
                  // todo: extract this into another component
                  const configurationsForVehicle = configurations?.filter(
                    (c) => c.vehicle === vehicle._id,
                  );

                  const isVehicleSelected =
                    selectedVehicle?._id === vehicle._id;
                  const isVehicleOpened = openedVehicleId === vehicle._id;

                  return (
                    <Paper
                      key={vehicle._id}
                      sx={{
                        width: '99%',
                        background: '#334657',
                        borderRadius: '6px',
                        border: `0.5px solid ${isVehicleSelected ? '#F6AE2D' : 'transparent'}`,
                        paddingY: '15px',
                        mt: 1,
                        ml: 1,
                        cursor: 'pointer',
                      }}
                    >
                      <Box
                        component="div"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={2}
                        onClick={() => {
                          setSelectedVehicle(vehicle);
                          setOpenedVehicleId(vehicle._id);
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <MobileCraneIcon />
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexGrow={1}
                          ml={2}
                        >
                          <Chip
                            sx={{
                              background: '#fff',
                              borderRadius: '4px',
                              width: '100px',
                            }}
                            label={
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  color: '#202D38',
                                }}
                              >
                                {vehicle.rego || 'N/A'}
                              </Typography>
                            }
                          />
                          <Typography sx={{ ml: 2, fontSize: '12px' }}>
                            FLEET: #{vehicle.fleetNumber || 'N/A'}{' '}
                          </Typography>
                        </Box>

                        {/* Right Icon */}
                        <Box
                          display="flex"
                          alignItems="center"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenedVehicleId(
                              isVehicleOpened ? null : vehicle._id,
                            );
                          }}
                        >
                          {isVehicleOpened ? (
                            <ArrowUpIcon />
                          ) : (
                            <ArrowDownIcon />
                          )}
                        </Box>
                      </Box>

                      {/* Configurations Dropdown */}
                      {isVehicleOpened &&
                        configurationsForVehicle &&
                        configurationsForVehicle.length > 0 && (
                          <>
                            <Box px={2} mt={2}>
                              <Select
                                variant="filled"
                                fullWidth
                                value={selectedConfiguration?._id || ''}
                                onChange={(e) => {
                                  const config = configurationsForVehicle.find(
                                    (c) => c._id === e.target.value,
                                  );
                                  setSelectedConfiguration(config);
                                }}
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    color: 'primary.main', // Replace with your desired color
                                  },
                                }}
                              >
                                {configurationsForVehicle.map((config) => (
                                  <MenuItem key={config._id} value={config._id}>
                                    {config.name ||
                                      `Configuration ${config._id}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                            {selectedConfiguration &&
                              openedVehicleId ==
                                selectedConfiguration.vehicle && (
                                <Box
                                  px={2}
                                  mt={2}
                                  pt={1}
                                  pb={1}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-evenly"
                                >
                                  <IconButton onClick={() => {}}>
                                    <ManIcon />
                                  </IconButton>
                                  <IconButton onClick={() => {}}>
                                    <DocIcon />
                                  </IconButton>
                                  <IconButton onClick={onClickRoute}>
                                    <RouteIcon />
                                  </IconButton>
                                  <IconButton onClick={() => {}}>
                                    <SaveRouteMapIcon />
                                  </IconButton>
                                </Box>
                              )}
                          </>
                        )}
                    </Paper>
                  );
                })}
              </Box>
            )}
            {displaySavedRoutes && (
              <Box>
                <Box
                  px={3}
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  sx={{ marginTop: '-10px' }}
                >
                  <IconButton onClick={onClickVehicle}>
                    <ArrowLeftIcon />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                      textAlign: 'center',
                      marginLeft: '2px',
                    }}
                  >
                    {selectedVehicle?.rego}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      textAlign: 'center',
                      backgroundColor: '#444444',
                      color: '#fff',
                      borderRadius: '12px',
                      padding: '1px 10px',
                      marginLeft: '10px',
                    }}
                  >
                    {selectedConfiguration?.name}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    marginTop: '10px',
                    marginLeft: '30px',
                    marginRight: '10px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                    SAVED ROUTES
                  </Typography>
                </Box>
                {displayRoutes?.map((route: any) => (
                  <Box
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    sx={{
                      marginTop: '10px',
                      marginLeft: '6px',
                      background: '#334657',
                      borderRadius: '6px',
                    }}
                    onClick={() => setSelectedSavedRoute(route)}
                  >
                    <Box
                      sx={{
                        padding: '10px 0 10px 10px',
                        width: '40%',
                      }}
                    >
                      <Typography sx={{ fontSize: '12px', textAlign: 'left' }}>
                        {/* STEVE THOMASON */}
                      </Typography>
                      <Typography sx={{ fontSize: '12px', textAlign: 'left' }}>
                        {format(
                          new Date(route.updatedAt),
                          'hhmma, dd-MMM-yyyy',
                        ).toUpperCase()}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '10px 0',
                        width: '30%',
                        marginLeft: '5px',
                      }}
                    >
                      <Typography sx={{ fontSize: '13px', textAlign: 'left' }}>
                        {route.start.name} - {route.end.name}
                      </Typography>
                      
                    </Box>
                    <Box
                    sx={{
                      padding: '10px 0',
                      width: '10%',
                      marginLeft: '10%',
                    }}
                  >
                    <IconButton sx={{}} onClick={() => handleDelete(route._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        )}

        <Grid
          item
          xs={displayLeftBar ? 8 : 12}
          sx={{ paddingTop: 0, marginTop: '-20px' }}
        >
          {route ? (
            <MapWithAutocomplete
              routeData={{
                geojsonData: route,
                ...selectedSavedRoute,
              }}
              company={String(company)}
              configuration={selectedConfiguration._id}
              switchLeftBar={onClickSwitchLeftBar}
              displayLeftBar={displayLeftBar}
            />
          ) : (
            <MapWithoutGeojson 
              switchLeftBar={onClickSwitchLeftBar}
              displayLeftBar={displayLeftBar}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
